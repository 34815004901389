import PropTypes from "prop-types"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { DevTool } from "@hookform/devtools"
import schema from "./Validation"
import { Grid } from "@mui/material"
import { AcademicLevel, Campus, Consentement, RequiredFields, Submit } from "components"
import { Email, InputText, PhoneNumber } from "components/fields"
import { useForms, useSalesforce } from "hooks"
import { defaultValues, specialities } from "services"
import { useEffect } from "react"
import MentionsLegales from "components/MentionsLegales"

const Form = ({ campaignId, campus, msc, productId }) => {
  const { recordTypeId, salesforce, setError, setOpenSnackbar } = useForms()
  // Styles
  // Form
  const methods = useForm({
    defaultValues: {
      ...specialities,
      campaignId: '',
      lead: {
        ...defaultValues
      }
    },
    resolver: yupResolver(schema)
  })
  const { control, setValue } = methods
  // State
  // Submit form
  const onSubmit = async data => {
    setOpenSnackbar(false)
    const lead = data.lead
    const campus = lead.Campus__c
    const campaignId = data.campaignId
    const defaultDataLead = {
      Division__c: 'ISG France',
      IONISSchool__c: 'ISG',
      LastForm__c: 'Documentation PDF',
      LeadSource: 'Internet: Demande de doc',
      MCS_MBA__c: true,
      RecordTypeId: recordTypeId
    }
    const finalLead = {...lead, ...defaultDataLead}
    try {
      const leadCreated = await salesforce.createLead(finalLead)
      if (campaignId !== undefined) {
        const campaignMember = {
          CampaignId: campaignId,
          LeadId: leadCreated.Id,
          Status: 'Requested'
        }
        await salesforce.createCampaignMember(campaignMember)
      }
      const isMsc = msc !== undefined ? `&msc=${msc}` : ''
      document.location.href=`http://isg.fr/msc-mba/retour-documentation/?ville=${campus}${isMsc}`
    } catch (error) {
      setError('Une erreur est survenue lors de la soumission du formulaire', error)
    }
  }
  // Watch
  // useEffect
  useEffect(() => {
    // Récupération du campus
    setValue('lead.Campus__c', campus)
  }, [campus, setValue])
  useEffect(() => {
    // Récupération de la campagne
    setValue('campaignId', campaignId)
  }, [campaignId, setValue])
  useEffect(() => {
    if (salesforce !== null && productId !== undefined) {
      const getData = async () => {
        const campaign = await salesforce.getCampaign(productId)

        return { campaign }
      }

      getData().then((value) => {
        const { campaign } = value
        setValue('campaignId', campaign.Id)
        setValue('lead.Campus__c', campaign.Campus__c)
        // setOpenBackdrop(false)
      }).catch((error) => {
        setError('Une erreur est survenue lors du chargement du formulaire', error)
      })
    }
  }, [campus, salesforce, setError])
  useSalesforce('ISG')

  return (
    <FormProvider {...methods}>
      <DevTool control={control} />
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <InputText control={control} label="Prénom" name="lead.FirstName" required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <InputText control={control} label="Nom" name="lead.LastName" required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Email control={control} label="Adresse e-mail" name="lead.Email" required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <PhoneNumber control={control} name="lead.MobilePhone" label="Téléphone" required />
        </Grid>
        <Grid item sm={campus === '' ? 6 : 12} xs={12}>
          <AcademicLevel section={false} />
        </Grid>
        {campus === '' && (
          <Grid item sm={6} xs={12}>
            <Campus label="Campus souhaité" />
          </Grid>
        )}
        <Consentement form="Documentation" school="ISG France" />
        <RequiredFields />
        <Submit onSubmit={onSubmit} />
        <MentionsLegales form="Documentation" school="ISG France" />
      </Grid>
    </FormProvider>
  )
}

Form.propTypes = {
  campaignId: PropTypes.string,
  campus: PropTypes.string,
  msc: PropTypes.string,
  productId: PropTypes.string
}

export default Form
