import { AcademicLevel, Campus, Country, Department, Email, FirstName, LastName, MobilePhone, MobilePhoneRequired, Salutation, Section, Specialites } from "services/validation"
import * as Yup from "yup"
import { matchIsValidTel } from "mui-tel-input"

const schemas = [
  Yup.object({
    academicLevel: Yup.string().required(`Le niveau d'étude est requis`),
    cycle: Yup.string().required('Le cycle est requis'),
    programme: Yup.string().required(`La formation est requise`),
    campus: Yup.string().required('Le campus est requis'),
    session: Yup.string().required('La session de rentrée est requise'),
    FirstName: Yup.string().required('Le prénom est requis').max(64, 'Le prénom doit contenir au maximum 64 caractères'),
    LastName: Yup.string().required('Le nom est requis').max(64, 'Le nom doit contenir au maximum 64 caractères'),
    email: Yup.string().email(`Le format de l'adresse e-mail est incorrect`).required(`L'adresse e-mail est requise`).max(40, `L'adresse e-mail doit contenir au maximum 40 caractères`),
    MobilePhone: Yup.string().required('Le mobile est requis').max(64, 'Le téléphone doit contenir au maximum 64 caractères').test('test-mobile-phone', 'Le numéro que vous avez renseigné semble incorrect',
      (val) => {
        return val === '' || matchIsValidTel(val)
      })
  }),
  Yup.object({
    email: Yup.string().when('$forgotPassword', {
      is: true,
      then: (schema) => schema.email(`Le format de l'adresse e-mail est incorrect`).required(`L'adresse e-mail est requise`).max(40, `L'adresse e-mail doit contenir au maximum 40 caractères`)
    })
    .when('$wantLogin', {
      is: true,
      then: (schema) => schema.email(`Le format de l'adresse e-mail est incorrect`).required(`L'adresse e-mail est requise`).max(40, `L'adresse e-mail doit contenir au maximum 40 caractères`)
    }),
    password: Yup.string().when('$forgotPassword', {
      is: false,
      then: (schema) => schema.min(8, 'Le mot de passe doit contenir au moins 8 caractères').required('Le mot de passe est requis')
    }),
    // password: Yup.string().min(8, 'Le mot de passe doit contenir au moins 8 caractères').required('Le mot de passe est requis'),
    passwordConfirmation: Yup.string().when('userExist', {
      is: false,
      then: (schema) => schema.oneOf([Yup.ref('password'), null], 'Les mots de passe ne correspondent pas').required('La confirmation du mot de passe est requise')
    })
  }),
  Yup.object({
    PostalCode: Yup.string().required('Le code postal est requis').max(20, 'Le code postal doit contenir au maximum 20 caractères'),
    Street: Yup.string().required(`La rue est requise`).max(255, `La rue doit contenir au maximum 255 caractères`),
    City: Yup.string().required('La ville est requise').max(40, `La ville doit contenir au maximum 40 caractères`),
    Country: Yup.string().required(`Le pays est requis`),
    // EstablishmentCountry: Yup.string().required(`Le pays de l'établissement est requis`),
    // EstablishmentCity: Yup.string().required('La ville de l\'établissement est requise'),
    // EstablishmentName: Yup.string().required('Le nom de l\'établissement est requis')
  }),
  Yup.object().shape({
    files: Yup
    .mixed()
    .nullable()
    // .required('Au moins un fichier est requis')
    // .test('files-required-or-uploaded', 'Au moins un fichier est requis', (value, context) => {
    //   const filesUploaded = context.parent.filesUploaded
    //   return (value && value.length > 0) || (filesUploaded && filesUploaded.length > 0)
    // })
    .test('fileType', 'Seuls les formats JPG, PNG et PDF sont acceptés', (files) => {
      if (!files || files.length === 0) return true
      return (
        files && Array.from(files).every((file) => ['image/jpeg', 'image/png', 'application/pdf'].includes(file.type))
      )
    })
    .test('totalSize', 'La taille totale des fichiers ne doit pas dépasser 20 Mo', (files) => {
      if (!files || files.length === 0) return true
      return (
        files && Array.from(files).reduce((acc, file) => acc + file.size, 0) <= 20 * 1024 * 1024
      )
    }),
    filesUploaded: Yup.array().of(Yup.object())
  }),
  Yup.object().shape({
    // CGV: Yup.boolean().oneOf([true], 'Vous devez accepter les conditions générales de vente'),
    CGV: Yup.boolean()
    .test(
      'cgv-required',
      'Vous devez accepter les conditions générales de vente',
      (value, context) => {
        const { boursier, EngineeringApplicationPayment } = context.parent
        console.log(value, boursier, EngineeringApplicationPayment)
        if (!boursier) {
          return value === true || EngineeringApplicationPayment === true
        }
        return true
      }
    )
    // .when('EngineeringApplicationPayment', {
    //   is: false,
    //   then: (schema) => schema.oneOf([true], 'Vous devez accepter les conditions générales de vente')
    // })
  })
]

export default schemas
