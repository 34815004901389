import { Grid } from "@mui/material"
import { CheckboxMultiple, RadioButton } from "components/fields"
import { useFormContext } from "react-hook-form"
import consentement from "json/consentement.json"
import { useEffect } from "react"

const ConsentementMultiple = () => {

  const { control, setValue, watch } = useFormContext()

  const watchConsentement = watch('consentement')
  const watchConsent = watch('consent')

  useEffect(() => {
    if (watchConsentement?.length > 0) {
      const mapConsentement = consentement.filter(el => watchConsentement.some(el2 => el.label === el2)).map(el => el.field)
      for (const consentement of mapConsentement) {
        setValue(consentement, true)
      }
    } else {
      consentement.map(el => (
        setValue(el.field, false)
      ))
    }
  }, [watchConsentement])

  useEffect(() => {
    if (watchConsent === 'Oui') {
      consentement.map(el => (
        setValue(el.field, true)
      ))
    } else {
      consentement.map(el => (
        setValue(el.field, false)
      ))
    }
  }, [watchConsent])

  const consent = [
    { label: 'Oui', value: 'Oui' },
    { label: 'Non', value: 'Non' }
  ]

  return (
    <>
      <Grid item xs={12}>
        <RadioButton control={control} label="J'accepte d'être contacté par email et messagerie" name="consent" options={consent} required />
      </Grid>
      {watchConsent === 'Non' && (
        <Grid item xs={12}>
          <CheckboxMultiple control={control} label="Je choisis mes préférences de contact" name="consentement" options={consentement} />
        </Grid>
      )}
    </>
  )
}

export default ConsentementMultiple
