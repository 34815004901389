const recordTypes = {
    'EngineeringSchoolLead': ["ESME", "EPITA", "IPSA", "Sup'Biotech", "EPITA IA Institut"],
    'BusinessSchoolLead': ["e-artsup", "ISEG MCS", "IONIS STM"],
    'ISG': ["ISG"]
};


function getRecordTypeName(division, recordType = recordTypes) {
    for (const [record, divisions] of Object.entries(recordType)) {
        if (divisions.includes(division)) {
            return record;
        }
    }
    return null;
}

export default getRecordTypeName;



