import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Grid } from "@mui/material";
import DropDownCheck from "components/fields/DropdownCheck";
import formations from "../services/formations.json";
import {Cursus} from "components"

const findFormations = (school, campus, academicLevel, formationsData) => {
    const formations = formationsData[school] || [];

    return formations.filter(formation => {
        const hasCampus = !formation.campus || formation.campus.includes(campus);
        const academicLevels = formation.academicLevels || formation.academicLevel || [];
        const hasAcademicLevel = !academicLevels.length || academicLevels.includes(academicLevel);

        return hasCampus && hasAcademicLevel;
    }).map(formation => ({
        label: formation.formation,
        field: formation.field,
        value: formation.formation  
    }));
};

const Formations = ({ school }) => {
    const { control, setValue, watch } = useFormContext();
    const [optionsFormations, setOptionsFormations] = useState([]);

    const campusArray = watch('lead.Campus__c');
    const academicLevel = watch('lead.AcademicLevel__c');
    const watchFormations = watch('formations');

    const campus = Array.isArray(campusArray) ? campusArray[0] : campusArray;

    useEffect(() => {
        if (campus && school && academicLevel) {
            const formationsBySchool = findFormations(school, campus, academicLevel, formations);
            setOptionsFormations(formationsBySchool);
            setValue('formations', []);
        }
    }, [academicLevel, campus, school, setValue]);

    useEffect(() => {
        optionsFormations.forEach(option => {
            setValue(option.field, false);
        });

        if (watchFormations?.length > 0) {
            const selectedFormations = optionsFormations
                .filter(el => watchFormations.includes(el.value))
                .map(el => el.field);

            selectedFormations.forEach(formation => {
                setValue(formation, true);
            });
        }
    }, [watchFormations, optionsFormations, setValue]);
    

    return (
        <>
            {optionsFormations.length > 0 && (
                <Grid item xs={12}>
                    {school === 'e-artsup' ? (
                        <Cursus ecole="e-artsup" label="Programme d'intérêt" />
                    ) : (
                        <DropDownCheck
                            control={control}
                            label="Formations"
                            name="formations"
                            options={optionsFormations}
                            required
                        />
                    )}
                </Grid>
            )}
        </>
    );
};

export default Formations;