import PropTypes from "prop-types"
import { CssBaseline,  } from "@mui/material"
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles"
import { default as Atelier } from "./Atelier/Form"
import { default as Conference } from "./Conference/Form"
import { default as Contact } from "./Contact/Form"
import { default as Documentation } from "./Documentation/Form"
import { default as EvenementGenerique } from "./EvenementGenerique/Form"
import { default as JourneeImmersion } from "./JourneeImmersion/Form"
import { default as JourneesPortesOuvertes } from "./JourneesPortesOuvertes/Form"
import { default as LivreBlanc } from "./LivreBlanc/Form"
import { default as Precandidature } from "./Candidature/Form"
import theme from "./Theme/index"
import Forms from "../Forms"
import { FormContextProvider } from "hooks/useForm"

const AtelierEartsup = () => {
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms>
          <CssBaseline />
          <Atelier />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

const ConferenceEartsup = ({ type }) => {
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms>
          <CssBaseline />
          <Conference type={type} />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

const ContactEartsup = () => {
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms>
          <CssBaseline />
          <Contact />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

const DocumentationEartsup = () => {
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms>
          <CssBaseline />
          <Documentation />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

const EvenementGeneriqueEartsup = () => {
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms>
          <CssBaseline />
          <EvenementGenerique />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

const JourneeImmersionEartsup = () => {
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms>
          <CssBaseline />
          <JourneeImmersion />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

const JourneePortesOuvertesEartsup = () => {
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms>
          <CssBaseline />
          <JourneesPortesOuvertes />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

const LivreBlancEtudiantEartsup = ({ campaignId }) => {
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms>
          <CssBaseline />
          <LivreBlanc campaignId={campaignId} />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

const LivreBlancParentEartsup = ({ campaignId }) => {
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms>
          <CssBaseline />
          <LivreBlanc campaignId={campaignId} parent={true} />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

const PrecandidatureEartsup = () => {
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms>
          <CssBaseline />
          <Precandidature />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

LivreBlancEtudiantEartsup.propTypes = {
  campaignId: PropTypes.string
}

LivreBlancParentEartsup.propTypes = {
  campaignId: PropTypes.string
}

ConferenceEartsup.propTypes = {
  type: PropTypes.string
}

export { AtelierEartsup, ConferenceEartsup, ContactEartsup, DocumentationEartsup, EvenementGeneriqueEartsup, JourneeImmersionEartsup, JourneePortesOuvertesEartsup, LivreBlancEtudiantEartsup, LivreBlancParentEartsup, PrecandidatureEartsup }
