import { AcademicLevel, Accompagnateurs, Availabilities, CampaignId, Campus, Email, FirstName, LastName, MobilePhoneRequired, Salutation, Section, SlotId, Specialites } from "services/validation"
import * as Yup from "yup"

const schema = Yup.object().shape({
  choix: Yup.array().min(1, 'Veuillez faire un choix'),
  consent: Yup.string().required('Veuillez faire un choix'),
  lead: Yup.object().shape({
    // ...AcademicLevel,
    AcademicLevel__c: Yup.string().when('LeadActivity__c', {
      is: val => val === 'Etudiant.e',
      then: (schema) => schema.required('Le champ Etudes actuelles est requis')
    }),
    ...Campus,
    CompanyLegacy__c: Yup.string().when('LeadActivity__c', {
      is: val => val === 'Entreprise',
      then: (schema) => schema.required('Le champ Société est requis')
    }),
    ...Email,
    ...FirstName,
    ...LastName,
    LeadActivity__c: Yup.string().required('Êtes vous un étudiant ou un professionel ?'),
    ...MobilePhoneRequired,
    Title: Yup.string().when('LeadActivity__c', {
      is: val => val === 'Entreprise',
      then: (schema) => schema.required('Le champ Profession est requis')
    })
  })
})

export default schema
