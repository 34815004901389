import PropTypes from "prop-types"
import { CssBaseline,  } from "@mui/material"
import { ThemeProvider } from "@mui/material/styles"
import { default as Contact } from "./Contact/Form"
import { default as DocumentationMsc } from "./DocumentationMsc/Form"
import { default as JourneesPortesOuvertes } from "./JourneesPortesOuvertes/Form"
import theme from "./Theme/index"
import { FormContextProvider } from "hooks/useForm"
import Forms from "../Forms"

const ContactISG = ({ campus, msc }) => {
  return (
    <FormContextProvider>
        <ThemeProvider theme={theme}>
          <Forms>
            <CssBaseline />
            <Contact campus={campus} msc={msc} />
          </Forms>
        </ThemeProvider>
    </FormContextProvider>
  )
}
const DocumentationMscISG = ({ campaignid, campus, msc, productid }) => {
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms>
          <CssBaseline />
          <DocumentationMsc campaignId={campaignid} campus={campus} msc={msc} productId={productid} />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}
const JourneesPortesOuvertesISG = () => {
  return (
    <FormContextProvider>
        <ThemeProvider theme={theme}>
          <Forms>
            <CssBaseline />
            <JourneesPortesOuvertes />
          </Forms>
        </ThemeProvider>
    </FormContextProvider>
  )
}

DocumentationMscISG.propTypes = {
  campaignid: PropTypes.string,
  campus: PropTypes.string,
  msc: PropTypes.string,
  productid: PropTypes.string
}

ContactISG.propTypes = {
  campus: PropTypes.string,
  msc: PropTypes.string
}

export { ContactISG, DocumentationMscISG, JourneesPortesOuvertesISG }
