import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { DevTool } from "@hookform/devtools"
import schema from "./Validation"
import { useState } from "react"
import { Grid } from "@mui/material"
import Formations from "../components/Formations"
import { AcademicLevel, Campaigns, Campus, ConsentementMultiple, Disponibilites, MentionsLegales, RequiredFields, Submit } from "components"
import { Email, InputText, PhoneNumber, RadioButton } from "components/fields"
import { useForms, useSalesforce } from "hooks"
import { campaigns, consent, consentement, defaultValues, getCampus, salutations, specialities } from "services"

const Form = () => {
  const { recordTypeId, salesforce, setError, setOpenSnackbar, setSnackMessage, setSnackSeverity } = useForms()
  // Styles
  // Form
  const methods = useForm({
    defaultValues: {
      ...campaigns,
      ...specialities,
      ...consent,
      formations: [],
      lead: {
        ...defaultValues,
        ...specialities.lead,
        ...consentement,
        Bachelor__c: false,
        CycleInge__c: false,
        MidYearSession__c: false,
        Salutation: '',
        Section__c: ''
      }
    },
    resolver: yupResolver(schema)
  })
  const { control } = methods
  // State
  // Submit form
  const onSubmit = async data => {
    setOpenSnackbar(false)
    const lead = data.lead
    const campaignId = data.campaignId
    const campus = lead.Campus__c
    const slotId = data.slotId
    const places = data.accompagnateurs || 0
    // Si on a choisi un créneau, on vérifie si le créneau est toujours dispo
    if (slotId !== '') {
      const slot = await salesforce.checkSlot(slotId, places)
      if (slot.length === 0) {
        setOpenSnackbar(true)
        setSnackMessage('Désolé les dernières places ont été prises. Veuillez choisir une autre date ou un autre créneau')
        setSnackSeverity('warning')
        return
      }
    }

    const defaultDataLead = {
      Division__c: 'IPSA',
      IONISSchool__c: 'IPSA',
      LastForm__c: 'Bachelor Day',
      LeadSource: 'Journée découverte',
      RecordTypeId: recordTypeId
    }
    const finalLead = {...lead, ...defaultDataLead}
    try {
      const leadCreated = await salesforce.createLead(finalLead)
      const campaignMember = {
        CampaignId: campaignId,
        LeadId: leadCreated.Id,
        Status: 'Registered'
      }
      if (slotId !== '') {
        campaignMember.EventSlot__c = slotId
        campaignMember.NumberOfPerson__c = places
      }
      await salesforce.createCampaignMember(campaignMember)
      document.location.href=`${process.env.REACT_APP_IPSA_URL}/ecole-ingenieur-aeronautique/retour-bachelors-days/?campaignId=${campaignId}`
    } catch (error) {
      setError('Une erreur est survenue lors de la soumission du formulaire', error)
    }
  }
  // Watch
  // useEffect
  useSalesforce('EngineeringSchoolLead')
  // Functions

  return (
    <FormProvider {...methods}>
      <DevTool control={control} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <RadioButton control={control} label="Civilité" name="lead.Salutation" options={salutations} required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <InputText control={control} label="Prénom" name="lead.FirstName" required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <InputText control={control} label="Nom" name="lead.LastName" required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Email control={control} label="Adresse e-mail" name="lead.Email" required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <PhoneNumber control={control} name="lead.MobilePhone" label="Téléphone" required />
        </Grid>
        <AcademicLevel academics={['Première', 'Terminale (BAC)', 'BAC', 'Bac +1', 'Bac +2', 'Bac +3']} school="IPSA" />
        <Campus label="Campus souhaité" options={getCampus('IPSA_BACHELOR')} />
        <Formations />
        <Campaigns contact={false} division="IPSA" school="IPSA" text="au Bachelor Day" type="Bachelor Day" />
        <ConsentementMultiple />
        <RequiredFields />
        <Submit onSubmit={onSubmit} />
        <MentionsLegales form="Bachelor Day" school="IPSA" />
      </Grid>
    </FormProvider>
  )
}

export default Form
