import { CssBaseline,  } from "@mui/material"
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles"
import { default as BachelorDay } from "./BachelorDay/Form"
import { default as Documentation } from "./Documentation/Form"
import { default as JourneesDecouverte } from "./JourneesDecouverte/Form"
import { default as JourneesPortesOuvertes } from "./JourneesPortesOuvertes/Form"
import theme from "./Theme/index"
import { FormContextProvider } from "hooks/useForm"
import Forms from "../Forms"

const BachelorDayIPSA = () => {
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms>
          <CssBaseline />
          <BachelorDay />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

const DocumentationIPSA = () => {
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms>
          <CssBaseline />
          <Documentation />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

const JourneesDecouverteIPSA = () => {
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms>
          <CssBaseline />
          <JourneesDecouverte />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

const JourneesPortesOuvertesIPSA = () => {
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms>
          <CssBaseline />
          <JourneesPortesOuvertes />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

export { BachelorDayIPSA, DocumentationIPSA, JourneesDecouverteIPSA, JourneesPortesOuvertesIPSA }
