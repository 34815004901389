import PropTypes from "prop-types"
import { Checkbox, ListItemText, MenuItem } from "@mui/material"
import { Controller } from "react-hook-form"
import DropdownCommon from "./DropdownCommon"

const DropDownCheck = ({ control, label, name, options, ...rest }) => {

  const renderOptions = (options, value) => {
    return options.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        <Checkbox checked={value.indexOf(option.value) > -1} size="small" />
        <ListItemText primary={option.label} />
      </MenuItem>
    ))
  }

  return (
    <Controller
      control={control}
      label={label}
      name={name}
      render={({
        field: { onChange, value },
        fieldState: { error }
      }) => (
        <DropdownCommon
          {...rest}
          error={error}
          label={label}
          multiple={true}
          name={name}
          onChange={onChange}
          renderOptions={renderOptions(options, value)}
          renderValue={(selected) => Array.isArray(selected) ? selected.join(', ') : selected}
          value={Array.isArray(value) ? value : []}
        />
      )}
    />
  )
}

DropDownCheck.propTypes = {
  control: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired
}

export default DropDownCheck
