import { createTheme } from "@mui/material/styles";
import { defaultTheme } from "components/Theme/Style";

const customTheme = (color, backgroundColor, hover, textColor, buttonColor, buttonTextColor) => {

    let themeOptions = {
        palette: {
            mode: 'light',
            primary: { main: color, dark: color },
            secondary: { main: '#ff0000' },
            neutral: { main: '#FFFFFF' },
            text: { primary: '#000000' },

            error: { main: '#FF5733' }
        },
        components: {
            MuiPaper: {
                styleOverrides: {
                    root: {
                        backgroundColor: backgroundColor 
                    }
                }
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        color: buttonTextColor, 
                        backgroundColor: buttonColor, 
                        '&:hover': {
                            backgroundColor: hover, 
                            color: buttonTextColor, 
                        },
                    },
                },
            },
        }
    };
    return createTheme(defaultTheme, themeOptions);
};

export { customTheme };
