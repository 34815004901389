import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { DevTool } from "@hookform/devtools"
import schema from "./Validation"
import { Grid } from "@mui/material"
import { AcademicLevel, Campus, ConsentementMultiple, RequiredFields, Submit } from "components"
import { CheckboxMultiple, Dropdown, Email, InputText, PhoneNumber } from "components/fields"
import { useForms, useSalesforce } from "hooks"
import { consent, consentement, defaultValues, getCampus } from "services"
import MentionsLegales from "components/MentionsLegales"
import { useEffect, useState } from "react"

const Form = () => {
  const { recordTypeId, salesforce, setError, setOpenSnackbar } = useForms()
  // Styles
  // Form
  const methods = useForm({
    defaultValues: {
      ...consent,
      campaignId: '',
      campaignConfId: '',
      choix: [],
      lead: {
        ...defaultValues,
        ...consentement,
        CompanyLegacy__c: '',
        LeadActivity__c: '',
        SchoolEstablishmentFreeText__c: '',
        Title: ''
      }
    },
    resolver: yupResolver(schema)
  })
  const { control, register, setValue, watch } = methods
  // State
  const leadActivity = [{ label: "Lycéen/étudiant", value: "Etudiant.e." }, { label: "Professionnel ", value: "Entreprise" }]
  const [choix, setChoix] = useState([])
  const [campaign, setCampaign] = useState([])
  const [campaigns, setCampaigns] = useState([])
  // Submit form
  const onSubmit = async data => {
    setOpenSnackbar(false)
    const lead = data.lead
    const campaignId = data.campaignId
    const campaignConfId = data.campaignConfId
    const campus = lead.Campus__c
    const lastForm = data.choix.length === 2 ? 'Hackaton et Live Event' : data.choix.includes('hackaton') ? 'Hackathon' : 'Live Event'

    const defaultDataLead = {
      Division__c: 'ISEG MCS',
      IONISSchool__c: 'ISEG MCS',
      LeadSource: 'Autre',
      LastForm__c: lastForm,
      RecordTypeId: recordTypeId
    }
    const finalLead = {...lead, ...defaultDataLead}
    try {
      const leadCreated = await salesforce.createLead(finalLead)
      // Campaign Member pour le hackathon
      if (data.choix.includes('hackaton')) {
        const campaignMember = {
          CampaignId: campaignId,
          LeadId: leadCreated.Id,
          Status: 'Registered'
        }
        await salesforce.createCampaignMember(campaignMember)
      }
      if (data.choix.includes('conference')) {
        const campaignMember = {
          CampaignId: campaignConfId,
          LeadId: leadCreated.Id,
          Status: 'RegisteredOnline'
        }
        await salesforce.createCampaignMember(campaignMember)
      }
      document.location.href=`${process.env.REACT_APP_ISEG_URL}/comondemain/retour-inscription-comondemain/?ville=${campus}&campaignId=${campaignId}`
    } catch (error) {
      setError('Une erreur est survenue lors de la soumission du formulaire', error)
    }
  }
  // Watch
  const watchLeadActivity = watch('lead.LeadActivity__c')
  const watchCampus = watch('lead.Campus__c')
  const watchChoix = watch('choix')
  // useEffect
  useEffect(() => {
    const conferenceChoice = { label: 'Je souhaite m’inscrire à une ou plusieurs conférences à distance', value: 'conference' }

    const updateChoices = () => {
      if (watchCampus === 'Nice') {
        setChoix([conferenceChoice])
        setValue('choix', ['conference'])
      } else if (watchCampus !== '') {
        setChoix([
          { label: `Je souhaite m’inscrire à l'hackathon du 20 novembre sur le campus de ${watchCampus}`, value: 'hackaton' },
          conferenceChoice
        ])
      }
    }

    updateChoices()
  }, [watchCampus])
  useEffect(() => {
    if (watchCampus !== '' && watchChoix.includes('hackaton')) {
      const campaign = campaigns.find(el => el.Campus__c === watchCampus)
      if (campaign !== undefined) {
        register('campaignId')
        setValue('campaignId', campaign.Id)
      }
    }
    if (watchCampus !== '' && watchChoix.includes('conference')) {
      const campaignWebinar = campaign[0]?.Id
      if (campaignWebinar !== undefined) {
        register('campaignConfId')
        setValue('campaignConfId', campaignWebinar)
      }
    }
  }, [watchChoix, watchCampus])
  useEffect(() => {
    if (salesforce !== null) {
      const getData = async () => {
        const campaigns = await salesforce.getCampaigns('ISEG MCS', 'ISEG MCS', 'Hackathon')
        const campaignConference = await salesforce.getCampaigns('ISEG MCS', 'ISEG MCS', 'Webinar')

        return { campaigns, campaignConference }
      }

      getData().then((value) => {
        const { campaigns, campaignConference } = value
        setCampaigns(campaigns)
        setCampaign(campaignConference)
        // setOpenBackdrop(false)
      }).catch((error) => {
        setError('Une erreur est survenue lors du chargement du formulaire', error)
      })
    }
  }, [salesforce, setError])
  useSalesforce('BusinessSchoolLead')
  // Functions

  return (
    <FormProvider {...methods}>
      <DevTool control={control} />
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <InputText control={control} label="Prénom" name="lead.FirstName" required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <InputText control={control} label="Nom" name="lead.LastName" required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Email control={control} label="Adresse e-mail" name="lead.Email" required />
        </Grid>
        <Grid item sm={6} xs={12}>
          <PhoneNumber control={control} name="lead.MobilePhone" label="Téléphone" required />
        </Grid>
        <Grid item xs={12}>
          <Dropdown control={control} label="Je suis" name="lead.LeadActivity__c" options={leadActivity} required />
        </Grid>
        {watchLeadActivity === 'Etudiant.e.' && (
          <AcademicLevel school="ISEG MCS" section={false} specialites={false} />
        )}
        {watchLeadActivity === 'Entreprise' && (
          <>
            <Grid item sm={6} xs={12}>
              <InputText control={control} label="Société" name="lead.CompanyLegacy__c" required />
            </Grid>
            <Grid item sm={6} xs={12}>
              <InputText control={control} label="Profession" name="lead.Title" required />
            </Grid>
          </>
        )}
        <Campus label="Je suis intéressé par le campus de" options={getCampus('ISEG MCS')} />
        {watchCampus !== '' && (
          <Grid item sm={6} xs={12}>
            <CheckboxMultiple control={control} label="" name="choix" options={choix} raw required />
          </Grid>
        )}
        <ConsentementMultiple />
        <RequiredFields />
        <Submit onSubmit={onSubmit} />
        <MentionsLegales form="JPO" school="ISEG MCS" />
      </Grid>
    </FormProvider>
  )
}

export default Form
