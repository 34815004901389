import * as Yup from 'yup';

const schemas = Yup.object().shape({
    lead: Yup.object().shape({
        Salutation: Yup.string()
            .when('$CivilityRequired', {
                is: true,
                then: (schema) => schema.required('La civilité est requise'),
                otherwise: (schema) => schema.notRequired()
            }),
        FirstName: Yup.string()
            .when('$firstNameRequired', {
                is: true,
                then: (schema) => schema.required('Le prénom est requis'),
                otherwise: (schema) => schema.notRequired()
            }),
        LastName: Yup.string()
            .when('$lastNameRequired', {
                is: true,
                then: (schema) => schema.required('Le nom est requis'),
                otherwise: (schema) => schema.notRequired()
            }),
        Email: Yup.string()
            .email('Format email invalide')
            .when('$emailRequired', {
                is: true,
                then: (schema) => schema.required('L\'email est requis'),
                otherwise: (schema) => schema.notRequired()
            }),
        MobilePhone: Yup.string()
            .when('$phoneNumberRequired', {
                is: true,
                then: (schema) => schema.required('Le numéro de téléphone est requis'),
                otherwise: (schema) => schema.notRequired()
            }),

        AcademicLevel__c: Yup.string()
            .when('$academicLevelRequired', {
                is: true,
                then: (schema) => schema.required('Le niveau académique est requis'),
                otherwise: (schema) => schema.notRequired()
            }),
        Campus__c: Yup.array()
            .when('$campusRequired', {
                is: true,
                then: (schema) => schema.test(
                    'required-if-empty',
                    'Le Campus est requis',
                    function (value) {
                        return Array.isArray(value) && value.length > 0;
                    }
                ),
                otherwise: (schema) => schema.notRequired()
            }),
        postMessage: Yup.string()
            .when('$postMessageRequired', {
                is: true,
                then: (schema) => schema.required('Le message est requis'),
                otherwise: (schema) => schema.notRequired()
            }),
        
    }),
})

export default schemas;
