import { Grid } from "@mui/material";
import { RadioButton } from "components/fields";
import { useFormContext } from "react-hook-form";

const Presentiel = () => {
    const { control, } = useFormContext();


    const presentielOptions = [
        { label: 'Oui', value: true },
        { label: 'Non', value: false }
    ];

    return (
        <Grid item xs={12}>
            <RadioButton
                control={control}
                label="Êtes-vous disponible en présentiel ?"
                name="onSite"
                options={presentielOptions}
                required
            />
        </Grid>
    );
};

export default Presentiel;
