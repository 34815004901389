export function findFormations(campus, academicLevel, formations) {
  const campusList = campus.split(',').map(c => c.trim())
  return formations.filter(formation => campusList.some(c => formation.campus.includes(c)) && formation.academicLevels.includes(academicLevel)).map(el => ({
    field: el.field,
    label: el.formation,
    value: el.formation
  }))
}

export function constructParams(formationsChoisies, formations) {
  const formationsParams = formations.filter(el => formationsChoisies.includes(el.formation)).map(el => '&programme[]=' + el.param)
  return formationsParams.join('')
}
