import { CssBaseline,  } from "@mui/material"
import { ThemeProvider } from "@mui/material/styles"
import { default as Candidature } from "./Candidature/Form"
import { default as Documentation } from "./Documentation/Form"
import theme from "./Theme/index"
import Forms from "../Forms"
import { FormContextProvider } from "hooks/useForm"

const CandidatureAdvance = ({ cycle = '', formation = '', keyForm = '', school = '' }) => {
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms>
          <CssBaseline />
          <Candidature defaultCycle={cycle} formation={formation} keyForm={keyForm} school={school} />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

const DocumentationAdvance = () => {
  return (
    <FormContextProvider>
      <ThemeProvider theme={theme}>
        <Forms>
          <CssBaseline />
          <Documentation />
        </Forms>
      </ThemeProvider>
    </FormContextProvider>
  )
}

export { CandidatureAdvance, DocumentationAdvance }
