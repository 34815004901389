// Date de naissance / nationalité (v2)
// bug du + pour le token (v2)

import { FormProvider, set, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { DevTool } from "@hookform/devtools"
import schemas from "./Validation"
import { Alert, Box, Button, FormHelperText, Grid, Typography } from "@mui/material"
import { AcademicLevel, Adresse, Consentement, ConsentementMultiple, Departments, RequiredFields, Submit } from "components"
import { Dropdown, DropDownCheck, Email, InputCheckbox, InputText, PhoneNumber, RadioButton } from "components/fields"
import { useForms, useSalesforce } from "hooks"
import { consent, consentement, defaultValues, salutations } from "services"
import MentionsLegales from "components/MentionsLegales"
// import cycles from "./cycles.json"
import niveaux from "./niveaux.json"
import pieces from "./pieces.json"
import { useEffect, useRef, useState } from "react"
import { LoadingButton } from "@mui/lab"
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import NavigatePrevIcon from '@mui/icons-material/NavigateBefore'
import CreditCardIcon from '@mui/icons-material/CreditCard';
import Navigation from "./Navigation"
import moment from "moment-timezone"
import "moment/locale/fr"
import queryString from "query-string"
import FileUpload from "components/fields/FileUpload"
import pagesretour from "./pagesretour.json"
import cgv from "./cgv.json"
import prices from "./prices.json"

const Form = ({ defaultCycle = '', keyForm = '', formation = '', school = '' }) => {
  const setLocalStorage = (key, data) => {
    const existingData = JSON.parse(localStorage.getItem(key) || '{}')
    const newData = { ...existingData, ...data }
    localStorage.setItem(key, JSON.stringify(newData))
  }

  const getLocalStorage = (keyForm, key) => {
    const data = JSON.parse(localStorage.getItem(keyForm) || '{}')
    return data[key]
  }
  const { recordTypeId, salesforce, setError, setOpenSnackbar } = useForms()
  const savedStep = parseInt(getLocalStorage(keyForm, 'step') || '0', 10)
  const savedConnected = getLocalStorage(keyForm, 'connected') === 'true'
  const savedBlocked = getLocalStorage(keyForm, 'blocked') === 'true'
  const savedWantLogin = getLocalStorage(keyForm, 'wantLogin') === true
  const savedForgotPassword = getLocalStorage(keyForm, 'forgotPassword') === true
  const { security_token, token } = queryString.parse(document.location.search)
  const refForm = useRef()
  // Styles
  // State
  const [activeStep, setActiveStep] = useState(savedStep)
  const [sessions, setSessions] = useState([])
  const [countries, setCountries] = useState([])
  const [cycles, setCycles] = useState([])
  const [campusList, setCampusList] = useState([])
  const [programList, setProgramList] = useState([])
  const [sessionList, setSessionList] = useState([])
  const [IonisConnectToValidated, setIonisConnectToValidated] = useState(false)
  const [resetPasswordMailSent, setResetPasswordMailSent] = useState(false)
  const [isConnected, setIsConnected] = useState(savedConnected)
  const [recordTypeIdOppy, setRecordTypeIdOppy] = useState('')
  const [wrongPassword, setWrongPassword] = useState(false)
  const [passwordChanged, setPasswordChanged] = useState(false)
  const [noOpportunity, setNoOpportunity] = useState(false)
  const [blocked, setBlocked] = useState(savedBlocked)
  const [loading, setLoading] = useState(false)
  const [listPieces, setListPieces] = useState([])
  const [attachments, setAttachments] = useState([])
  const [payboxData, setPayboxData] = useState({})
  const [cgvUrls, setCgvUrls] = useState({})
  const [isParcoursup, setIsParcoursup] = useState(false)
  const [wantLogin, setWantLogin] = useState(savedWantLogin)
  const [forgotPassword, setForgotPassword] = useState(savedForgotPassword)
  const [currentProgramName, setCurrentProgramName] = useState('')
  const [payLater, setPayLater] = useState(false)
  const [price, setPrice] = useState(0)
  // Form
  const defaultValues = {
    academicLevel: '',
    boursier: false,
    campus: '',
    City: '',
    CGV: false,
    Country: '',
    cycle: '',
    ApplicationPayment: false,
    EstablishmentCity: '',
    EstablishmentCountry: '',
    EstablishmentName: '',
    programme: '',
    session: '',
    FirstName: '',
    LastName: '',
    email: '',
    MarketingOptIn: false,
    MobilePhone: '',
    password: '',
    passwordConfirmation: '',
    Phone: '',
    PostalCode: '',
    Street: '',
    userExist: false,
    files: null,
    filesUploaded: null,
    // wantLogin: false,
    // forgotPassword: false,
  }
  const savedData = getLocalStorage(keyForm, 'formData')
  const initialValues = { ...defaultValues, ...savedData }
  const schema = schemas[activeStep]
  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
    context: { forgotPassword, payLater, wantLogin }
  })
  const { control, getValues, handleSubmit, reset, setValue, trigger, watch } = methods
  // Submit form
  const next = async (data) => {
    setNoOpportunity(false)
    setLoading(true)
    let userExist = data.userExist
    console.log(data)
    const isValid = await trigger()
    console.log(isValid)
    if (isValid) {
      const defaultAccount = {
        AcademicLevel__pc: data.academicLevel,
        Division__pc: 'Inge',
        FirstName: data.FirstName,
        IONIS_School__pc: 'Inge',
        LastName: data.LastName,
        MarketingOptIn__c: data.MarketingOptIn,
        PersonEmail: data.email,
        PersonMailingCity: data.City,
        PersonMailingCountry: data.Country,
        PersonMailingPostalCode: data.PostalCode,
        PersonMailingStreet: data.Street,
        PersonMobilePhone: data.MobilePhone,
        Phone: data.Phone,
        RecordTypeId: recordTypeId,
        SchoolSupCountry__c: data.EstablishmentCountry,
        SchoolSupCity__c: data.EstablishmentCity,
        SchoolSupName__c: data.EstablishmentName,
        ScolarShipHolder__c: data.boursier
      }

      let existingAccount = {}
      console.log(activeStep)
      let existingId = ''
      if (activeStep === 0) {
        // On vérifie si il a déjà un compte
        console.log('on vérifie si il a déjà un compte')
        existingAccount = await salesforce.getAccountExisting(data.email, school).account
        console.log(existingAccount)
        if (existingAccount) {
          existingId = existingAccount.Id
        }
      }
      let accountVariable = {}
      console.log(data.AccountId)
      if (data.AccountId || existingId) {
        console.log('AccountId existe', data.AccountId)
        accountVariable = {
          ...accountVariable,
          Id: data.AccountId || existingAccount.Id,
          StageStop__c: 'infos'
        }
      }
      if (school === 'EPITA') {
        accountVariable = {
          ...accountVariable,
          EpitaApplication__c: true
        }
      }
      if (school === 'ESME') {
        accountVariable = {
          ...accountVariable,
          ESMEApplication__c: true
        }
      }
      if (school === 'IPSA') {
        accountVariable = {
          ...accountVariable,
          IPSAApplication__c: true
        }
      }
      if (school === `Sup'Biotech`) {
        accountVariable = {
          ...accountVariable,
          SupbiotechApplication__c: true
        }
      }
      const account = { ...defaultAccount, ...accountVariable }
      if (activeStep === 0) {
        // On vérifie si il a déjà un compte
        userExist =  await salesforce.checkUser(email, school, 'Inge')
        setValue('userExist', userExist)
        console.log(userExist)
      }
      console.log(activeStep)
      console.log(account)
      if ((activeStep === 0 && !userExist) || activeStep > 1) {
        const updatedAccount = await salesforce.updateAccount(account)
        console.log(updatedAccount)
        setValue('AccountId', updatedAccount.accountId)

        let competitionType = ''
        let paralleleAdmission = false

        switch(cycle) {
          case 'Cycle Bachelor':
            competitionType = 'Bachelor'
            break
          case 'Cycle master':
            competitionType = 'MSC et MBA'
            break
          default:
            competitionType = 'Advance Para'
            paralleleAdmission = true
            break
        }

        let opportunityVariable = {}
        if (data.opportunityId) {
          console.log('OpportunityId existe', data.opportunityId)
          opportunityVariable = {
            ...opportunityVariable,
            Id: data.opportunityId
          }
        }

        const fullSession = sessions.find(session => session.Id === data.session)

        console.log(fullSession)

        const defaultOpportunity = {
          AccountId: updatedAccount.accountId,
          BachelorProgramm__c: cycle === 'Cycle Bachelor',
          BackToBackSession__c: fullSession.MidYearSession__c,
          CampusCityWish__c: data.campus,
          City_in_charge__c: data.campus,
          CompetitionType__c: data.competitionType || competitionType,
          CloseDate: new Date(),
          InternationalSession__c: fullSession.Division__c.includes('International') !== undefined,
          LearningSession__c: fullSession.FormationType__c === 'Apprenti',
          Name: activeStep === 0 ? 'Candidature' : undefined,
          OnlineApplication__c: true,
          ParallelAdmissionSession__c: paralleleAdmission,
          RecordTypeId: recordTypeIdOppy,
          Session__c: data.session,
          StageName: data.stageName || 'En cours',
          StageStop__c: activeStep + 1
        }

        const opportunity = { ...defaultOpportunity, ...opportunityVariable }

        const opportunityUpdated = await salesforce.updateOpportunity(opportunity)
        console.log('UPDATE OPPY', opportunityUpdated)
        setValue('opportunityId', opportunityUpdated.Id)
      }
      if (activeStep === 0 && userExist) {
        // On récupère le compte et les opportunités, si l'opportunité de l'école n'existe pas, on l'a crée
        const { account, opportunities } = await salesforce.getAccountExisting(email, school)
        console.log(opportunities)
        if (opportunities.length === 0) {
          let competitionType = ''
          let paralleleAdmission = false

          switch(cycle) {
            case 'Cycle Bachelor':
              competitionType = 'Bachelor'
              break
            case 'Cycle master':
              competitionType = 'MSC et MBA'
              break
            default:
              competitionType = 'Advance Para'
              paralleleAdmission = true
              break
          }

          const fullSession = sessions.find(session => session.Id === data.session)

          console.log(fullSession)

          const defaultOpportunity = {
            AccountId: account.Id,
            BackToBackSession__c: fullSession.MidYearSession__c,
            CampusCityWish__c: data.campus,
            City_in_charge__c: data.campus,
            CompetitionType__c: data.competitionType || competitionType,
            CloseDate: new Date(),
            LearningSession__c: fullSession.FormationType__c === 'Apprenti',
            Name: 'Candidature',
            OnlineApplication__c: true,
            ParallelAdmissionSession__c: paralleleAdmission,
            RecordTypeId: recordTypeIdOppy,
            Session__c: data.session,
            StageName: 'En cours',
            StageStop__c: 2
          }
          const opportunity = { ...defaultOpportunity }
          const opportunityCreated = await salesforce.updateOpportunity(opportunity)
          console.log('CREATE OPPY', opportunityCreated)
          setValue('opportunityId', opportunityCreated.Id)
        }
      }
      if (activeStep === 3) {
        try {
          const attachments = await salesforce.addAttachmentToSobject(data.files, data.AccountId)
          console.log(attachments)
          for (let i = 0; i < attachments.length; i++) {
            await salesforce.checkAttachment(attachments[i].batchId, attachments[i].jobId)
            await salesforce.resultAttachment(attachments[i].batchId, attachments[i].jobId)
          }
          setValue('files', null)
        } catch (error) {

        }
      }
      setActiveStep(activeStep + 1)
      setLoading(false)
    }
  }
  const createAccount = async (data) => {
    setLoading(true)
    if (activeStep === 1) {
      console.log(data.userExist)
      console.log(userExist)
      if (!userExist) {
        try {
          const ionisConnectCreated = await salesforce.createUser('Inge', document.location.href, recordTypeId, school.replace(/'/g, ''), '', data)
          setValue('IonisConnectId', ionisConnectCreated.id)
          setIonisConnectToValidated(true)
        } catch (error) {
          console.log(error)
        }
      }
      if (userExist) {
        await loginUser(data)
      }
    }
    setLoading(false)
  }
  const login = async (data) => {
    setLoading(true)
    setWrongPassword(false)
    setPasswordChanged(false)
    if (activeStep === 1) {
      await loginUser(data)
    }
  }

  const logout = async () => {
    reset({})
    setActiveStep(0)
    setIsConnected(false)
    setBlocked(false)
    setValue('userExist', false)
    // localStorage.clear()
    setTimeout(() => localStorage.clear(), 0)
    reset({})
  }

  const wantRegister = async () => {
    setActiveStep(0)
    // setValue('wantLogin', false)
    setWantLogin(true)
    setValue('userExist', false)
    // setValue('forgotPassword', false)
    setForgotPassword(false)
  }

  const alreadyAccount = async () => {
    setActiveStep(1)
    setValue('userExist', true)
    // setValue('wantLogin', true)
    setWantLogin(true)
  }

  const passwordForgotten = async () => {
    // reset({})
    console.log('password forgotten')
    // setValue('wantLogin', false)
    setWantLogin(false)
    console.log('sdfsdgsjdgl')
    // setValue('userExist', false)
    // setValue('forgotPassword', true)
    setForgotPassword(true)
    setValue('wrongPassword', false)
    setWrongPassword(false)
  }

  const passwordForgottenSent = async (data) => {
    const isValid = await trigger()
    if (isValid) {
      const resetpassword = await salesforce.resetPassword(data.email, 'Inge')
      setResetPasswordMailSent(true)
    }
  }

  const changePassword = async (data) => {
    console.log(data)

    const isValid = await trigger()
    if (isValid) {
      const changePassword = await salesforce.updatePassword(data.password, security_token)
      setPasswordChanged(true)
      setValue('wantChangePassword', false)
      // setValue('wantLogin', true)
      setWantLogin(true)
      setValue('userExist', true)
      setValue('wantChangePassword', false)
      const queryParams = queryString.parse(document.location.search)
      console.log(queryParams)
      const url = window.location.href
      const [baseUrl] = url.split('?')
      if (queryParams.security_token) {
        console.log('on supprime le security token')
        delete queryParams.security_token
        console.log(queryParams)
        const newUrl = `${baseUrl}?${queryString.stringify(queryParams)}`
        window.history.replaceState({}, '', newUrl)
      }
    }

  }

  const loginUser = async (data) => {
    try {
      const verifyUser = await salesforce.verifyUser(data.email, data.password, school, 'Inge')
      console.log(verifyUser)
      const { account } = await salesforce.getAccount(data.email, verifyUser, 'Inge')
      console.log(account)
      // setIsConnected(true)
      // setActiveStep(activeStep + 1)
      // localStorage.setItem('connected', 'true')
      setValue('AccountId', account.Id)
      setValue('IonisConnectId', verifyUser)
      setValue('City', account.PersonMailingCity)
      setValue('Country', account.PersonMailingCountry)
      setValue('FirstName', account.FirstName)
      setValue('LastName', account.LastName)
      setValue('MobilePhone', account.PersonMobilePhone)
      setValue('PostalCode', account.PersonMailingPostalCode)
      setValue('Street', account.PersonMailingStreet)
      setValue('boursier', account.ScolarShipHolder__c)
      setValue('academicLevel', account.AcademicLevel__pc.split('_')[0])
      // setValue('')
      const opportunities = await salesforce.getOpportunities(account.Id)
      const finalOpportunity = opportunities.find(opportunity => opportunity.Division__c === school && opportunity.CompetitionType__c !== 'Parcoursup' && opportunity.CompetitionType__c !== 'CPGE')
      console.log(finalOpportunity)
      if (finalOpportunity === undefined) {
        setActiveStep(0)
        setLoading(false)
        setNoOpportunity(true)
        return
      }
      setValue('opportunityId', finalOpportunity.Id)
      console.log(finalOpportunity.Session__c)
      console.log(data.session)
      if (finalOpportunity.Session__c !== data.session && data.session !== '' && data.session !== undefined) {
        console.log('on bloque tout')
        // On bloque tout
        setBlocked(true)
        setActiveStep(2)
        setLoading(false)
        setIsConnected(true)
        const session = sessions.find(session => session.Id === finalOpportunity.Session__c)
        console.log(session)
        setCurrentProgramName(session.Program__r.FullName__c)
        // localStorage.setItem('blocked', 'true')
        setLocalStorage(keyForm, { blocked: 'true' })
        return
      }
      setValue('session', finalOpportunity.Session__c)
      setValue('campus', finalOpportunity.City_in_charge__c)
      setValue('stageName', finalOpportunity.StageName)
      setValue('competitionType', finalOpportunity.CompetitionType__c)
      setValue('ApplicationPayment', finalOpportunity.ApplicationPayment__c)
      await salesforce.updateLastConnection(account.Id)
      setLoading(false)
      setIsConnected(true)
      setLocalStorage(keyForm, { connected: 'true' })
      setActiveStep(parseInt(finalOpportunity.StageStop__c))
      // if (verifyUser) {

      // }
    } catch (error) {
      console.log(error)
      setWrongPassword(true)
      setLoading(false)
    }
  }

  const goToBank = async data => {
    const isValid = await trigger()
    if (isValid) {
      localStorage.clear()
      refForm.current.submit()
    }
  }

  const onPayLater = async data => {
    setLoading(true)
    setPayLater(true)

    const values = getValues()

    onSubmit(values)
  }

  const previous = () => {
    setActiveStep(activeStep - 1)
  }
  const onSubmit = async data => {
    setOpenSnackbar(false)
    setLoading(true)
    console.log('on valide la candidature')
    const opportunity = {
      Id: data.opportunityId,
      // AccountId: data.AccountId,
      // OnlineApplication__c: true,
      // RecordTypeId: recordTypeIdOppy,
      // Session__c: data.session,
      StageName: 'Candidature à valider',
    }

    const opportunityUpdated = await salesforce.updateOpportunity(opportunity)
    console.log('UPDATE OPPY', opportunityUpdated)
    setValue('opportunityId', opportunityUpdated.Id)
    console.log(school, cycle)
    const session = sessions.find(session => session.Id === data.session)
    const { pages : { enregistre } } = pagesretour.find(page => page.school === school && session.Program__r.Cycle__c === page.cycle)
    // const { pages : { enregistre } } = pagesretour.find(page => page.school === school && cycle === page.cycle)
    console.log(enregistre)
    localStorage.clear()
    document.location.href = enregistre
  }
  // Watch
  const cycle = watch('cycle')
  const academicLevel = watch('academicLevel')
  const email = watch('email')
  const programmeId = watch('programme')
  const userExist = watch('userExist')
  // const wantLogin = watch('wantLogin')
  // const forgotPassword = watch('forgotPassword')
  const wantChangePassword = watch('wantChangePassword')
  const accountId = watch('AccountId')
  const opportunityId = watch('opportunityId')
  const ApplicationPayment = watch('ApplicationPayment')
  const boursier = watch('boursier')
  const stageName = watch('stageName')
  // useEffect
  useEffect(() => {
    // Sauvegarder dans localStorage chaque fois que les valeurs du formulaire changent
    const filteredData = (data) => {
      // Exclure les champs contenant des fichiers
      const { files, ...filtered } = data;
      return filtered;
    }

    const subscription = watch((value) => {
      const filteredFormData = filteredData(value)
      // localStorage.setItem('formData', JSON.stringify(filteredFormData))
      setLocalStorage(keyForm, { formData: filteredFormData })
    })

    // localStorage.setItem('step', activeStep)
    setLocalStorage(keyForm, { step: activeStep })
    setLocalStorage(keyForm, { wantLogin: wantLogin })
    setLocalStorage(keyForm, { forgotPassword: forgotPassword })

    // Cleanup
    return () => subscription.unsubscribe()
  }, [watch, activeStep, wantLogin, forgotPassword])

  useEffect(() => {
    if (salesforce !== null) {
      const getData = async () => {
        const sessions = await salesforce.getSessions(school)
        const countries = await salesforce?.getCountries('0Nt08000000bqgi', ['France', 'Belgique', 'Suisse'])
        const recordTypeId = await salesforce.getRecordTypeId('Engineering Schools Application')

        return { countries, recordTypeId, sessions }
      }

      getData().then((value) => {
        const { countries, recordTypeId, sessions } = value
        setSessions(sessions)
        setCountries(countries)
        setRecordTypeIdOppy(recordTypeId)
        // setOpenBackdrop(false)
      }).catch((error) => {
        setError('Une erreur est survenue lors du chargement du formulaire', error)
      })
    }
  }, [salesforce, setError])
  useEffect(() => {
    if (defaultCycle !== '' && sessions.length > 0) {
      const cycles = [...new Set(sessions.map(session => session.Program__r.Cycle__c).filter(cycle => cycle))].map(cycle => ({ label: cycle, value: cycle }))
      setCycles(cycles)
      setValue('cycle', defaultCycle)
      changeCycle(defaultCycle)
    }
  }, [defaultCycle, sessions, setValue])
  useSalesforce('EngineeringSchoolApplicant')

  // useEffect(() => {
  //   if (cycle !== '') {
  //     const cycles = [...new Set(sessions.map(session => session.Program__r.Cycle__c).filter(cycle => cycle))].map(cycle => ({ label: cycle, value: cycle }))
  //     setCycles(cycles)
  //   }
  // }, [cycle, sessions])
  useEffect(() => {
    // const cycles = localStorage.getItem('cycles')
    // const programmes = localStorage.getItem('programmes')
    // const campus = localStorage.getItem('campus')
    // const sessions = localStorage.getItem('sessions')
    const cycles = getLocalStorage(keyForm, 'cycles')
    const programmes = getLocalStorage(keyForm, 'programmes')
    const campus = getLocalStorage(keyForm, 'campus')
    const sessions = getLocalStorage(keyForm, 'sessions')
    if (cycles) {
      setCycles(cycles)
    }
    if (programmes) {
      setProgramList(programmes)
    }
    if (campus) {
      setCampusList(campus)
    }
    if (sessions) {
      setSessionList(sessions)
    }
  }, [])

  useEffect(() => {
    const price = prices.find(price => price.school === school)
    if (prices !== undefined) {
      setPrice(price.price)
    }
  }, [school])

  useEffect(() => {
    console.log('token', token)
    if (token !== undefined && token !== '' && salesforce !== null) {
      console.log('on passe')
      const getData = async () => {
        const account = await salesforce.verifyConfirmationToken('Inge', token)
        const opportunities = await salesforce.getOpportunities(account.Id)
        const opportunity = {
          Id: opportunities[0].Id,
          StageStop__c: 2
        }
        await salesforce.updateOpportunity(opportunity)
        await salesforce.updateLastConnection(account.Id)

        console.log(account)
        console.log(opportunities)

        return { account, opportunities }
      }

      getData().then((value) => {
        const { account, opportunities } = value
        setIsConnected(true)
        // On recupère les informations de l'utilisateur
        setValue('FirstName', account.FirstName)
        setValue('LastName', account.LastName)
        setValue('email', account.PersonEmail)
        setValue('MobilePhone', account.PersonMobilePhone)
        setValue('City', account.City)
        setValue('Country', account.Country)
        setValue('PostalCode', account.PostalCode)
        setValue('Street', account.Street)
        setValue('boursier', account.ScolarShipHolder__c)
        setValue('academicLevel', account.AcademicLevel__pc.split('_')[0])
        // Récupération de l'oppy
        setValue('opportunityId', opportunities[0].Id)
        setValue('session', opportunities[0].Session__c)
        setValue('stageName', opportunities[0].StageName)
        setValue('competitionType', opportunities[0].CompetitionType__c)
        setValue('stageStop', opportunities[0].StageStop__c)
        // localStorage.setItem('connected', 'true')
        setLocalStorage(keyForm, { connected: 'true' })
        setActiveStep(2)
        const queryParams = queryString.parse(document.location.search)
        console.log(queryParams)
        const url = window.location.href
        const [baseUrl] = url.split('?')
        if (queryParams.token) {
          console.log('on supprime le token')
          delete queryParams.token
          console.log(queryParams)
          const newUrl = `${baseUrl}?${queryString.stringify(queryParams)}`
          window.history.replaceState({}, '', newUrl)
        }
        // setOpenBackdrop(false)
      }).catch((error) => {
        setError('Une erreur est survenue lors de la validation du token', error)
      })
    }
  }, [salesforce, token])

  useEffect(() => {
    if (security_token !== undefined && security_token !== '') {
      setActiveStep(1)
      // setValue('wantLogin', false)
      setWantLogin(false)
      // setValue('forgotPassword', false)
      setForgotPassword(false)
      setValue('userExist', false)
      setValue('wantChangePassword', true)
    }
  }, [security_token])

  useEffect(() => {
    if (noOpportunity) {
      // On récupère le niveau académique et on trouve le cycle
      const cycles = [...new Set(sessions.filter(session => session.Program__r.Eligible_Academic_Level__c !== null && session.Program__r.Eligible_Academic_Level__c.includes(academicLevel)).map(session => session.Program__r.Cycle__c))].map(cycle => ({ label: cycle, value: cycle }))
      setCycles(cycles)
      setLocalStorage(keyForm, { cycles: cycles })
    }
  }, [noOpportunity])

  useEffect(() => {
    if (activeStep === 3) {
      // console.log(academicLevel)
      // console.log(cycle)
      // const listPieces = pieces.filter(piece => piece.academics.includes(academicLevel) && piece.cycles.includes(cycle)).map(piece => piece.piece)
      // setListPieces(listPieces)
      let formulaire = ''
      switch (cycle) {
        case 'Cycle Bachelor':
            formulaire = school + ' Bachelor'
          break
        case 'Cycle master':
          formulaire = school + ' Master'
          break
        case 'Cycle apprentissage':
          formulaire = 'Apprentissage'
          break
        default:
          formulaire = 'Advance'
          break
      }
      const documents = getRequiredDocuments(formulaire, academicLevel)
      setListPieces(documents)
    }

    if (activeStep === 4 && salesforce !== null && sessions.length > 0) {
      const getData = async () => {
        // const localAccount = JSON.parse(localStorage.getItem('formData'))
        const localAccount = getLocalStorage(keyForm, 'formData')
        const account = {
          Id: localAccount.AccountId,
          FirstName: localAccount.FirstName,
          LastName: localAccount.LastName,
          PersonEmail: localAccount.email,
          PersonMobilePhone: localAccount.MobilePhone,
          PersonMailingCity: localAccount.City,
          PersonMailingCountry: localAccount.Country,
          PersonMailingPostalCode: localAccount.PostalCode,
          PersonMailingStreet: localAccount.Street
        }

        const cgvurls = cgv.find(cgv => cgv.school === school)

        setCgvUrls(cgvurls)

        const session = sessions.find(session => session.Id === localAccount.session)

        const { pages : { effectue, annule, refuse } } = pagesretour.find(page => page.school === school && session.Program__r.Cycle__c === page.cycle)

        const payboxData = await salesforce.getPayboxData(account, school.replace(/'/g, ''), { paiement_ok: effectue, paiement_refuse: refuse, paiement_annule: annule, paiement_traitement: process.env.REACT_APP_PAYBOX_TRAITEMENT }, opportunityId)

        return { payboxData }
      }

      getData().then((value) => {
        const { payboxData } = value
        setPayboxData(payboxData)
      })
    }
  }, [activeStep, salesforce, sessions])

  useEffect(() => {
    if (activeStep === 3 && salesforce !== null) {
      const getData = async () => {
        const attachments = await salesforce.getAttachments(accountId)

        return { attachments }
      }
      getData().then((value) => {
        const { attachments } = value
        setAttachments(attachments)
        setValue('filesUploaded', attachments)
      })
    }
  }, [activeStep, accountId, salesforce])

  useEffect(() => {

    if (programmeId !== '' && academicLevel === 'Terminale (BAC)') {
      const session = sessions.find(session => session.Program__r.Id === programmeId && session.PSupFormationCode__c !== null)
      console.log(session)

      if (session !== undefined) {
        setIsParcoursup(true)
      } else {
        setIsParcoursup(false)
      }
    } else {
      setIsParcoursup(false)
    }

  }, [academicLevel, programmeId, sessions])

  const getRequiredDocuments = (formulaire, niveauEtude) => {
    const documents = pieces.formulaires[formulaire]?.[niveauEtude];
    if (!documents) return [];
    return documents.map(docKey => pieces.documents[docKey]);
  }

  const changeAcademicLevel = async (academicLevelSelected) => {
    console.log(academicLevelSelected, academicLevel)
    // reset cycle, campus, programme and session
    const checkProgramme = sessions.filter(session => session.Program__r.Eligible_Academic_Level__c !== null && session.Program__r.Eligible_Academic_Level__c.includes(academicLevelSelected) && session.Program__r.Id === formation)
    console.log(checkProgramme)
    if (checkProgramme === undefined || checkProgramme.length === 0) {
      setValue('cycle', '')
      setValue('programme', '')
      setValue('campus', '')
      setValue('session', '')
      setProgramList([])
      setCampusList([])
      setSessionList([])
    }
    const cycles = [...new Set(sessions.filter(session => session.Program__r.Eligible_Academic_Level__c !== null && session.Program__r.Eligible_Academic_Level__c.includes(academicLevelSelected)).map(session => session.Program__r.Cycle__c))].map(cycle => ({ label: cycle, value: cycle }))
    setCycles(cycles)
    // localStorage.setItem('cycles', JSON.stringify(cycles))
    setLocalStorage(keyForm, { cycles: cycles })
    if (defaultCycle === '') {
      console.log(defaultCycle, '!!!!!!')
      setValue('cycle', '')
    } else {
      if (cycles.find(c => c.value === defaultCycle)) {
        console.log('même cycle')
        changeCycle(defaultCycle, academicLevelSelected)
      } else {
        console.log("pas même cycle")
        setValue('cycle', '')
        setValue('programme', '')
        setValue('campus', '')
        setValue('session', '')
        setProgramList([])
        setCampusList([])
        setSessionList([])
      }
    }
  }

  const changeCycle = async (cycle, acaLevel) => {
    console.log(acaLevel)
    const finalAcademic = acaLevel || academicLevel
    // reset campus, programme and session
    const checkProgrammes = sessions.filter(session => session.Program__r.Cycle__c === cycle && session.Program__r.Eligible_Academic_Level__c !== null && session.Program__r.Eligible_Academic_Level__c.includes(finalAcademic) && session.Program__r.Id === formation)
    console.log(formation)
    if (checkProgrammes === undefined || checkProgrammes.length === 0) {
      setValue('programme', '')
      setValue('campus', '')
      setValue('session', '')
      setCampusList([])
      setSessionList([])
    }


    const programmes = [...new Map(sessions.filter(session => session.Program__r.Cycle__c === cycle && session.Program__r.Eligible_Academic_Level__c !== null && session.Program__r.Eligible_Academic_Level__c.includes(finalAcademic)).map(session => [session.Program__r.Id, { label: session.Program__r.FullName__c, value: session.Program__r.Id }])).values()]

    programmes.sort((a, b) => {
      const nameA = a.label.toLowerCase()  // Assurer que la comparaison est insensible à la casse
      const nameB = b.label.toLowerCase()
      if (nameA < nameB) return -1  // Si a < b, a vient avant b
      if (nameA > nameB) return 1   // Si a > b, b vient avant a
      return 0  // Si les deux sont égaux
    })
    setValue('cycle', cycle)
    console.log(academicLevel)
    console.log(programmes)
    setProgramList(programmes)
    if (programmes.length === 1) {
      console.log(programmes[0].value)
      setValue('programme', programmes[0].value)
      changeProgram(programmes[0].value)
    }
    console.log(cycles.find(c => c.value === cycle))
    console.log(academicLevel)
    console.log(acaLevel)
    if (formation !== '' && finalAcademic === '') {
      setValue('programme', formation)
      changeProgram(formation)
    } else {
      console.log('cesst pas pareil !!!!')
      // setValue('cycles', [])
      // setValue('cycle', '')
      // setValue('formations', [])
      // setValue('campus', [])
      // setValue('session', [])
      // setCampusList([])
      // setSessionList([])
    }
    // localStorage.setItem('programmes', JSON.stringify(programmes))
    setLocalStorage(keyForm, { programmes: programmes })
  }

  const changeProgram = async (programmeId) => {
    // reset campus and session
    setValue('campus', '')
    setValue('session', '')
    setSessionList([])

    const listeCampus = [...new Set(sessions.filter(session => session.Program__r.Id === programmeId).filter(campus => campus).map(session => ({ label: session.Campus__c, value: session.Campus__c })))]

    listeCampus.sort((a, b) => {
      const nameA = a.label.toLowerCase()  // Assurer que la comparaison est insensible à la casse
      const nameB = b.label.toLowerCase()
      if (nameA < nameB) return -1  // Si a < b, a vient avant b
      if (nameA > nameB) return 1   // Si a > b, b vient avant a
      return 0  // Si les deux sont égaux
    })

    setCampusList(listeCampus)
    if (listeCampus.length === 1) {
      setValue('campus', listeCampus[0].value)
      changeCampus(listeCampus[0].value, programmeId)
    }
    // localStorage.setItem('campus', JSON.stringify(listeCampus))
    setLocalStorage(keyForm, { campus: listeCampus })
  }

  const changeCampus = async (campus, programme) => {
    // reset session
    setValue('session', '')

    console.log(campus)
    console.log(programmeId)

    console.log(programme)
    const finalProgram = programme !== undefined ? programme : programmeId

    const listesessions = [...new Set(sessions.filter(session => session.Program__r.Id === finalProgram && session.Campus__c === campus).map(session => ({ label: moment(session.StartDate__c).tz("Europe/Paris").format("MMMM yy"), value: session.Id })))]
    console.log(listesessions)
    setSessionList(listesessions)
    if (listesessions.length === 1) {
      setValue('session', listesessions[0].value)
    }
    // localStorage.setItem('sessions', JSON.stringify(listesessions))
    setLocalStorage(keyForm, { sessions: listesessions })
  }

  return (
    <FormProvider {...methods}>
      <DevTool control={control} />
      {(activeStep === 0) && (
        <Grid item xs={12} display="flex" justifyContent="center">
          <Button color="primary" onClick={alreadyAccount} variant="contained">Vous avez déjà un compte ?</Button>
        </Grid>
      )}
      {(activeStep > 1 && isConnected) && (
        <Grid item xs={12} display="flex" justifyContent="center">
          <Button color="primary" onClick={logout} variant="outlined">Se déconnecter</Button>
        </Grid>
      )}
      <Navigation activeStep={activeStep} steps={['Choix de formation', 'Connexion / Création du compte', 'Informations personnelles', 'Pièces jointes', 'Paiement']} />
        <Grid container spacing={2}>
        {token !== undefined && (
          <Grid item xs={12} display="flex" justifyContent="center">
            <Grid item xl={3} xs={12}>
              <Alert severity="info">Validation de votre adresse e-mail en cours.</Alert>
            </Grid>
          </Grid>
        )}
        {activeStep === 0 && (
          <>
            {noOpportunity && (
              <Grid item xs={12} display="flex" justifyContent="center">
                <Grid item xl={3} xs={12}>
                  <Alert severity="info">Nous n'avons pas trouvé de candidature à votre nom pour {school.replace(/'/g, '')}, merci de renseigner les champs ci-dessous.</Alert>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12} display="flex" justifyContent="center">
              <Grid item xl={3} xs={12}>
                <Dropdown disabled={sessions.length === 0} name="academicLevel" label="Votre niveau d'étude actuel" onValueChange={changeAcademicLevel} options={niveaux} required />
              </Grid>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Grid item xl={3} xs={12}>
                <Dropdown disabled={cycles.length === 0} name="cycle" label="Votre choix de cycle" onValueChange={changeCycle} options={cycles} required />
              </Grid>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Grid item xl={3} xs={12}>
                <Dropdown disabled={programList.length === 0} name="programme" label="Formation souhaitée" onValueChange={changeProgram} options={programList} required />
              </Grid>
            </Grid>
            {isParcoursup && (
              <Grid item xs={12} display="flex" justifyContent="center">
                <Grid item xl={3} xs={12}>
                  <Alert severity="info">Si vous êtes actuellement en Terminale dans un lycée français ou bachelier en cours de cursus post-bac et que vous souhaitez intégrer cette formation, vous devez déposer un dossier sur Parcoursup et sélectionner le vœu correspondant.</Alert>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12} display="flex" justifyContent="center">
              <Grid item xl={3} xs={12}>
                <Dropdown disabled={campusList.length === 0} name="campus" label="Campus d'étude souhaité" onValueChange={changeCampus} options={campusList} required />
              </Grid>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Grid item xl={3} xs={12}>
                <Dropdown disabled={sessionList.length === 0} name="session" label="Pour la rentrée de" onValueChange={undefined} options={sessionList} required />
              </Grid>
            </Grid>
            <Grid item sx={{ mt: 4 }} xs={12} display="flex" justifyContent="center">
              <Grid item xl={3} xs={12}>
                <InputText control={control} label="Prénom" name="FirstName" required />
              </Grid>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Grid item xl={3} xs={12}>
                <InputText control={control} label="Nom" name="LastName" required />
              </Grid>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Grid item xl={3} xs={12}>
                <Email control={control} label="Adresse e-mail" name="email" required />
              </Grid>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Grid item xl={3} xs={12}>
                <PhoneNumber control={control} name="MobilePhone" label="Mobile" required />
              </Grid>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Grid item xl={3} xs={12}>
                <InputCheckbox control={control} label={`En soumettant ce formulaire, j'accepte que les informations saisies soient exploitées par ${school.replace(/'/g,
                '')}`} name="MarketingOptIn" />
              </Grid>
            </Grid>
          </>
        )}
        {activeStep === 1 && !isConnected && token === undefined && (
          <>
            <Grid item xs={12} display="flex" justifyContent="center">
              {userExist && !forgotPassword && (
                <Grid item xl={4} xs={12}>
                  <Typography variant="h6">Connexion à votre compte</Typography>
                  <Typography variant="body1">Pour continuer, veuillez saisir votre mot de passe.</Typography>
                </Grid>
              )}
              {userExist && forgotPassword && (
                <Grid item xl={4} xs={12}>
                  <Typography variant="h6">Réinitialisation de votre mot de passe</Typography>
                  <Typography variant="body1">Pour continuer, veuillez saisir votre adresse e-mail.</Typography>
                </Grid>
              )}
              {!userExist && !wantChangePassword && (
                <Grid item xl={4} xs={12}>
                  <Typography variant="h6">Création de votre compte</Typography>
                  <Typography variant="body1">Pour poursuivre votre candidature, vous devez vous créer un mot de passe.</Typography>
                </Grid>
              )}
              {wantChangePassword && (
                <Grid item xl={4} xs={12}>
                  <Typography variant="h6">Changement de mon mot de passe</Typography>
                  <Typography variant="body1">Pour continuer, veuillez saisir un nouveau mot de passe.</Typography>
                </Grid>
              )}
            </Grid>
            {(wantLogin || forgotPassword) && (
              <Grid item xs={12} display="flex" justifyContent="center">
                <Grid item xl={3} xs={12}>
                  <InputText control={control} label="Adresse e-mail" name="email" type="email" required />
                  <Grid item xs={12}>
                    <Grid item xs={6}>
                      {forgotPassword && (
                        <Button onClick={wantRegister} sx={{ textTransform: 'none' }}>Revenir à l'étape précédente</Button>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {!forgotPassword && (
              <Grid item xs={12} display="flex" justifyContent="center">
                <Grid item xl={3} xs={12}>
                  <InputText control={control} label="Votre mot de passe" name="password" type="password" required />
                  {userExist && (
                    <Grid item xs={12} display="flex" justifyContent="center">
                      <Grid item xs={6}>
                        {wantLogin && (
                          <Button onClick={wantRegister} sx={{ textTransform: 'none' }}>Revenir à l'étape précédente</Button>
                        )}
                      </Grid>
                      <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end'}}>
                        <Button onClick={passwordForgotten} sx={{ textTransform: 'none' }}>Mot de passe oublié ?</Button>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
            {!userExist && (
              <Grid item xs={12} display="flex" justifyContent="center">
                <Grid item xl={3} xs={12}>
                  <InputText control={control} label="Confirmation de votre mot de passe" name="passwordConfirmation" type="password" required />
                </Grid>
              </Grid>
            )}
            {wrongPassword && (
              <Grid item xs={12} display="flex" justifyContent="center">
                <Grid item xl={3} xs={12}>
                  <Alert severity="error">L'e-mail ou le mot de passe que vous avez renseigné est incorrect.</Alert>
                </Grid>
              </Grid>
            )}
            {IonisConnectToValidated && (
              <Grid item xs={12} display="flex" justifyContent="center">
                <Grid item xl={3} xs={12}>
                  <Alert severity="info">Un e-mail de confirmation a été envoyé à l'adresse <strong>{email}</strong>. Veuillez cliquer sur le lien de confirmation qui se trouve dans cet e-mail afin de finaliser la création de votre compte.</Alert>
                </Grid>
              </Grid>
            )}
            {resetPasswordMailSent && (
              <Grid item xs={12} display="flex" justifyContent="center">
                <Grid item xl={3} xs={12}>
                  <Alert severity="info">Un e-mail pour réinitialiser votre mot de passe a été envoyé à l'adresse <strong>{email}</strong>.</Alert>
                </Grid>
              </Grid>
            )}
            {passwordChanged && (
              <Grid item xs={12} display="flex" justifyContent="center">
                <Grid item xl={3} xs={12}>
                  <Alert severity="info">Votre mot de passe a bien été modifié.</Alert>
                </Grid>
              </Grid>
            )}
          </>
        )}
        {/* {activeStep === 1 && isConnected && (
          <Grid item xs={12} display="flex" justifyContent="center">
            <Grid item xl={4} xs={12}>
              <Alert severity="info">Vous êtes déjà connecté.</Alert>
            </Grid>
          </Grid>
        )} */}
        {activeStep === 2 && isConnected && !blocked && (
          <>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Grid item xl={3} xs={12}>
                <InputText control={control} label="Prénom" name="FirstName" required />
              </Grid>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Grid item xl={3} xs={12}>
                <InputText control={control} label="Nom" name="LastName" required />
              </Grid>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Grid item xl={3} xs={12}>
                <Dropdown disabled={true} name="academicLevel" label="Niveau académique" options={niveaux} required />
              </Grid>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Grid item xl={3} xs={12}>
                <PhoneNumber control={control} name="MobilePhone" label="Mobile" required />
              </Grid>
            </Grid>
            <Grid sx={{ mt: 4 }} item xs={12} display="flex" justifyContent="center">
              <Grid item xl={3} xs={12}>
                <Typography variant="body1">Adresse postale</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Grid item xl={3} xs={12}>
                <InputText control={control} label="Rue" multiline name="Street" required rows={3} />
              </Grid>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Grid item xl={3} xs={12}>
                <InputText control={control} label="Code postal" name="PostalCode" required />
              </Grid>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Grid item xl={3} xs={12}>
                <InputText control={control} label="Ville" name="City" required />
              </Grid>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Grid item xl={3} xs={12}>
                <Dropdown control={control} label="Pays" name="Country" options={countries} required />
              </Grid>
            </Grid>
            {/* <Grid item xs={12} display="flex" justifyContent="center">
              <Grid item xl={3} xs={12}>
                <Typography variant="h6">Dernier établissement fréquenté</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Grid item xl={3} xs={12}>
                <Dropdown control={control} label="Pays" name="EstablishmentCountry" options={countries} required />
              </Grid>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Grid item xl={3} xs={12}>
                <InputText control={control} label="Ville" name="EstablishmentCity" required />
              </Grid>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Grid item xl={3} xs={12}>
                <InputText control={control} label="Nom de l'établissement" name="EstablishmentName" required />
              </Grid>
            </Grid> */}
            <Grid item xs={12} display="flex" justifyContent="center">
              <Grid item xl={3} xs={12}>
                <InputCheckbox control={control} label={`Je suis boursier de l'Éducation nationale`} name="boursier" />
              </Grid>
            </Grid>
          </>
        )}
        {activeStep === 3 && isConnected && !blocked && (
          <>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Grid item xl={3} xs={12}>
                {/* <Typography variant="h6">Création de votre mot de passe</Typography> */}
                <Typography sx={{ fontWeight: 'bold', mb: 2 }} variant="body1">Nous vous remercions de joindre les pièces justificatives de la liste ci-dessous :</Typography>
                <ul>
                  {listPieces.map((piece, index) => (
                    <li key={index}>{piece}</li>
                  ))}
                </ul>
                <Alert severity="info">
                  <ul>
                    <li>Vos pièces jointes doivent être à l'un des formats suivants : JPG, PNG, PDF</li>
                    <li>L'ensemble des pièces jointes ne doivent pas excéder 20Mo.</li>
                    <li>Avant de joindre vos documents pensez à les nommer de manière à ce que le service des Admissions puisse les identifier facilement. Exemples CV.pdf pour le CV, Bulletins de note pour Bulletins de notes des 2 dernières années.</li>
                  </ul>
                </Alert>
                <Typography sx={{ fontWeight: 'bold', mt: 3 }} variant="body1">Vous avez chargé les pièces suivantes :</Typography>
                <ul>
                  {attachments.map((attachment, index) => (
                    <li key={index}>{attachment.Name}</li>
                  ))}
                </ul>
              </Grid>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
            <Grid item xl={3} xs={12}>
              <FileUpload control={control} name="files" />
            </Grid>
          </Grid>
          </>
        )}
        {activeStep === 4 && isConnected && !blocked && (
          <>
            <Grid item xs={12} display="flex" justifyContent="center">
            <Grid item xl={3} xs={12}>
              {ApplicationPayment && stageName === 'En cours' && (
                <Alert severity="info">Nous avons bien reçu votre paiement. Vous pouvez continuer à soumettre votre candidature.</Alert>
              )}
              {stageName === 'Candidature à valider' && (
                <Alert severity="info">Vous avez déjà une candidature et vous ne pouvez pas la modifier via ce formulaire. Si vous souhaitez postuler pour un autre programme de l’école ou bien avoir des informations sur l’avancement de votre candidature nous vous invitons à nous contacter. Lien vers la page contact du site.</Alert>
              )}
              {!ApplicationPayment && (
                <>
                  <Typography sx={{ mb: 2 }} variant="h6">Frais de candidature</Typography>
                  {!boursier && (
                    <Typography sx={{ mb: 2 }} variant="body1">Les frais de candidature sont de {price} €.</Typography>
                  )}
                  {boursier && (
                    <Typography sx={{ mb: 2 }} variant="body1">Les frais de candidature sont gratuits pour les boursiers sur présentation d'un justificatif de l'année en cours.</Typography>
                  )}
                  {!boursier && (
                    <InputCheckbox control={control} label={<Typography variant="body2">En cochant cette case, je reconnais avoir pris connaissance des <a href={cgvUrls.cgv} target="_blank" rel="noreferrer">Conditions Générales de Vente</a> du site <a href={cgvUrls.url} target="_blank" rel="noreferrer">{cgvUrls.home}</a> et je les accepte.</Typography>} name="CGV" />
                  )}
                </>
              )}
            </Grid>
          </Grid>
          </>
        )}
        {activeStep > 1 && !isConnected && (
          <Grid item xs={12} display="flex" justifyContent="center">
            <Grid item xl={4} xs={12}>
              <Alert severity="info">Vous devez vous connecter pour continuer.</Alert>
            </Grid>
          </Grid>
        )}
        {activeStep > 1 && blocked && (
          <Grid item xs={12} display="flex" justifyContent="center">
            <Grid item xl={4} xs={12}>
              <Alert severity="info">Vous avez déjà une candidature {school.replace(/'/g, '')} {currentProgramName}. Merci de vous rapprocher du service des admissions pour finaliser votre candidature sur la formation.</Alert>
            </Grid>
          </Grid>
        )}
        <Grid item xs={5} sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          {activeStep > 2 && (
            <LoadingButton color="primary" startIcon={<NavigatePrevIcon />} onClick={previous} loadingPosition="end" variant="outlined">Précédent</LoadingButton>
          )}
        </Grid>
        <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          {activeStep === 4 && isConnected && !blocked && !ApplicationPayment && !boursier && (
            <LoadingButton sx={{ ml: 15 }} color="primary" loading={loading} onClick={onPayLater} variant="contained">Payer plus tard</LoadingButton>
          )}
        </Grid>
        <Grid item xs={activeStep > 0 ? 5 : 8} sx={{ display: 'flex', justifyContent: activeStep > 0 ? 'flex-start' : 'flex-end', mt: 2 }}>
          {((activeStep !== 1 && activeStep !== 4 && isConnected && !blocked) || (activeStep === 0 && !isConnected)) && (
            <LoadingButton color="primary" endIcon={<NavigateNextIcon />} loading={loading} onClick={handleSubmit(next)} variant="contained">Suivant</LoadingButton>
          )}
          {activeStep === 1 && !userExist && !IonisConnectToValidated && !wantChangePassword && token === undefined && (
            <LoadingButton color="primary" endIcon={<NavigateNextIcon />} loading={loading} onClick={handleSubmit(createAccount)} variant="contained">Créer mon compte</LoadingButton>
          )}
          {activeStep === 1 && !userExist && !IonisConnectToValidated && wantChangePassword && (
            <LoadingButton color="primary" endIcon={<NavigateNextIcon />} loading={loading} onClick={handleSubmit(changePassword)} variant="contained">Changer mon mot de passe</LoadingButton>
          )}
          {activeStep === 1 && userExist && !isConnected && !forgotPassword && (
            <LoadingButton color="primary" endIcon={<NavigateNextIcon />} loading={loading} onClick={handleSubmit(login)} variant="contained">Je me connecte</LoadingButton>
          )}
          {activeStep === 1 && userExist && !isConnected && forgotPassword && (
            <LoadingButton color="primary" endIcon={<NavigateNextIcon />} loading={loading} onClick={handleSubmit(passwordForgottenSent)} variant="contained">Réinitialiser mon mot de passe</LoadingButton>
          )}
          {activeStep === 4 && isConnected && !blocked && (ApplicationPayment || boursier) && stageName === 'En cours' && (
            <LoadingButton color="primary" endIcon={<NavigateNextIcon />} loading={loading} onClick={handleSubmit(onSubmit)} variant="contained">Valider ma candidature</LoadingButton>
          )}
          {activeStep === 4 && isConnected && !blocked && !ApplicationPayment && !boursier && (
            <form
              method="POST"
              action={process.env.REACT_APP_PAYBOX_URL}
              ref={refForm}
            >
              <input type="hidden" name="PBX_SITE" value={payboxData.pbxSite} />
              <input type="hidden" name="PBX_RANG" value={payboxData.pbxRang} />
              <input type="hidden" name="PBX_IDENTIFIANT" value={payboxData.pbxIdentifiant} />
              <input type="hidden" name="PBX_TOTAL" value={payboxData.pbxTotal} />
              <input type="hidden" name="PBX_DEVISE" value={payboxData.pbxDevise} />
              <input type="hidden" name="PBX_CMD" value={payboxData.pbxCmd} />
              <input type="hidden" name="PBX_SHOPPINGCART" value={payboxData.pbxShoppingCart} />
              <input type="hidden" name="PBX_PORTEUR" value={payboxData.pbxPorteur} />
              <input type="hidden" name="PBX_BILLING" value={payboxData.pbxBilling} />
              <input type="hidden" name="PBX_RETOUR" value={payboxData.pbxRetour} />
              <input type="hidden" name="PBX_HASH" value={payboxData.pbxHash} />
              <input type="hidden" name="PBX_TIME" value={payboxData.pbxTime} />
              <input type="hidden" name="PBX_EFFECTUE" value={payboxData.pbxEffectue} />
              <input type="hidden" name="PBX_REFUSE" value={payboxData.pbxRefuse} />
              <input type="hidden" name="PBX_ANNULE" value={payboxData.pbxAnnule} />
              <input type="hidden" name="PBX_REPONDRE_A" value={payboxData.pbxRepondreA} />
              <input type="hidden" name="PBX_HMAC" value={payboxData.pbxHmac} />

              <Button color="primary" startIcon={<CreditCardIcon />} onClick={handleSubmit(goToBank)} variant="contained">Payer</Button>
            </form>
          )}
        </Grid>
      </Grid>
    </FormProvider>
  )
}

export default Form
