const CAMPUS = {
  PARIS: {
    label: 'Paris',
    value: 'Paris'
  },
  BORDEAUX: {
    label: 'Bordeaux',
    value: 'Bordeaux'
  },
  LILLE: {
    label: 'Lille',
    value: 'Lille'
  },
  LYON: {
    label: 'Lyon',
    value: 'Lyon'
  },
  MONTPELLIER: {
    label: 'Montpellier',
    value: 'Montpellier'
  },
  NANTES: {
    label: 'Nantes',
    value: 'Nantes'
  },
  NICE: {
    label: 'Nice',
    value: 'Nice'
  },
  PARIS_CENTRE: {
    label: 'Paris',
    value: 'Paris - Centre'
  },
  RENNES: {
    label: 'Rennes',
    value: 'Rennes'
  },
  STRASBOURG: {
    label: 'Strasbourg',
    value: 'Strasbourg'
  },
  TOULOUSE: {
    label: 'Toulouse',
    value: 'Toulouse'
  },
  TOURS: {
    label: 'Tours',
    value: 'Tours'
  }
}

const getCampus = (school) => {
  switch (school) {
    case "e-artsup":
      return [CAMPUS.PARIS, CAMPUS.BORDEAUX, CAMPUS.LILLE, CAMPUS.LYON, CAMPUS.MONTPELLIER, CAMPUS.NANTES, CAMPUS.NICE, CAMPUS.STRASBOURG, CAMPUS.TOULOUSE, CAMPUS.TOURS]
    case "EPITA":
      return [CAMPUS.PARIS, CAMPUS.LYON, CAMPUS.RENNES, CAMPUS.STRASBOURG, CAMPUS.TOULOUSE]
    case "EPITA IA Institut":
      return [CAMPUS.PARIS]
    case "Epitech Digital":
      return [CAMPUS.PARIS, CAMPUS.BORDEAUX, CAMPUS.LYON]
    case "ESME":
      return [CAMPUS.PARIS, CAMPUS.BORDEAUX, CAMPUS.LILLE, CAMPUS.LYON]
    case "ESME_EVENT":
      return [CAMPUS.PARIS_CENTRE, CAMPUS.BORDEAUX, CAMPUS.LILLE, CAMPUS.LYON]
    case "IPSA":
      return [CAMPUS.PARIS, CAMPUS.LYON, CAMPUS.TOULOUSE]
    case "IPSA_BACHELOR":
      return [CAMPUS.PARIS, CAMPUS.TOULOUSE]
    case "ISEG MCS":
      return [CAMPUS.PARIS, CAMPUS.BORDEAUX, CAMPUS.LILLE, CAMPUS.LYON, CAMPUS.NANTES, CAMPUS.NICE, CAMPUS.STRASBOURG, CAMPUS.TOULOUSE]
    case "Sup'Biotech":
      return [CAMPUS.PARIS, CAMPUS.LYON]
    default:
      return []
  }
}

export { getCampus }
