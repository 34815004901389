import { Button, FormControl, FormHelperText, List, ListItem } from "@mui/material"
import { Controller, useFormContext } from "react-hook-form"
import UploadIcon from '@mui/icons-material/Upload';
import DeleteIcon from '@mui/icons-material/Delete';

const FileUpload = ({ control, name }) => {

  const { setValue } = useFormContext()

  const onChange = (e, field) => {
    e.preventDefault()
    let files = []
    if (e.target.files.length > 0) {
      const existingFiles = field.value || []
      console.log(e.target.files)
      for (let i = 0; i < e.target.files.length; i++) {
        console.log(e.target.files[i])
        const file = e.target.files[i]
        const newFile = new File([file], `${file.name}`, { type: file.type })
        files.push(newFile)
      }
      console.log('files')
      console.log(files)
      setValue(name, [...existingFiles, ...files])
    }
  }



  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <FormControl error={!!error}>
            <Button
              startIcon={<UploadIcon />}
              variant="outlined"
              component="label"
              sx={{ mb: 2 }}
            >
              Ajouter des fichiers
              <input
                // {...field}
                type="file"
                hidden
                multiple
                onChange={(e) => onChange(e, field)}
              />
            </Button>

            {error && <FormHelperText>{error.message}</FormHelperText>}
          </FormControl>

          {field.value && (
              <List>
                {Array.from(field.value).map((file, index) => (
                  <ListItem key={index}>
                    <Button color="error" startIcon={<DeleteIcon />} onClick={() => field.onChange(Array.from(field.value).filter((_, i) => i !== index))}></Button> {file.name} ({(file.size / 1024 / 1024).toFixed(2)} Mo)
                  </ListItem>
                ))}
              </List>
            )}
        </>
      )}
    />
  )
}

export default FileUpload
