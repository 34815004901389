import axios from "axios";
import "moment/locale/fr";

class Salesforce {
  constructor(options) {
    const endpoint = options.eda ? "/eda" : "/";
    this.axiosSfConnect = axios.create({
      baseURL: `${process.env.REACT_APP_ENDPOINT}${endpoint}`,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  async checkSlot(slotId, places) {
    const query = await this.axiosSfConnect({
      method: "get",
      url: "/lead/checkSlot",
      params: { id: slotId, places: places },
    });

    if (query.data.success) {
      return query.data.data;
    } else {
      throw new Error("Erreur récupération créneau");
    }
  }

  async createLead(lead) {
    const query = await this.axiosSfConnect({
      method: "post",
      url: "/lead/createLead",
      data: lead,
    });

    if (query.data.success) {
      return query.data.lead;
    } else {
      throw new Error("Erreur création du lead");
    }
  }

  async createCampaignMember(campaignMember) {
    const query = await this.axiosSfConnect({
      method: "post",
      url: "/lead/createCampaignMember",
      data: campaignMember,
    });

    if (query.data.success) {
      return query.data.campaignMember;
    } else {
      throw new Error("Erreur création du membre de campagne");
    }
  }

  async createCampaignMemberExternal(campaignMember, externalId) {
    const query = await this.axiosSfConnect({
      method: "post",
      url:"/lead/createCampaignMemberExternal",
      data:{campaignMember,externalId}
    })
    if (query.data.success) {
      return query.data.campaignMember;
    } else {
      throw new Error("Erreur création du memebre de campagne")
    }
  }

  async createSlotMember(slotMember) {
    const query = await this.axiosSfConnect({
      method: "post",
      url: "/lead/createSlotMember",
      data: slotMember,
    });

    if (query.data.success) {
      return query.data.slotMember;
    } else {
      throw new Error("Erreur création du membre de slot");
    }
  }

  async createTask(task) {
    const query = await this.axiosSfConnect({
      method: "post",
      url: "/lead/createTask",
      data: task,
    });

    if (query.data.success) {
      return query.data.task;
    } else {
      throw new Error("Erreur création tache");
    }
  }

  async getAcademicLevels(recordTypeId, division, academics = null) {
    const query = await this.axiosSfConnect({
      method: "get",
      url: "/lead/getAcademicLevel",
      params: {
        division: division,
        recordTypeId: recordTypeId,
        academics: academics,
      },
    });

    if (query.data.success) {
      return query.data.data;
    } else {
      throw new Error("Impossible de récupérer les niveaux académiques");
    }
  }

  async getCountries(id, firstValues) {
    const query = await this.axiosSfConnect({
      method: "get",
      url: "/lead/getCountries",
      params: { id: id, firstValues: firstValues },
    });

    if (query.data.success) {
      return query.data.data;
    } else {
      throw new Error("Impossible récupérer les pays");
    }
  }


  async getCampaign(productId) {
    const query = await this.axiosSfConnect({
      method: "get",
      url: "/lead/getCampaigns",
      params: { productId },
    });
    //si le champ est rempli productId recupere la campagne "externe"
    if (query.data.success && query.data.data.length > 0) {
      return query.data.data[0];
    } else {
      throw new Error("Impossible récupérer les campagnes");
    }
  }
  async getCampaigns(division, school, type) {
    const query = await this.axiosSfConnect({
      method: "get",
      url: "/lead/getCampaigns",
      params: { division, school, type },
    });

    if (query.data.success) {
      return query.data.data;
    } else {
      throw new Error("Impossible récupérer les campagnes");
    }
  }

  async getCampaignsCandidature(division, school, type) {
    const query = await this.axiosSfConnect({
      method: "get",
      url: "/lead/getCampaignsCandidature",
      params: { division, school, type },
    });

    if (query.data.success) {
      return query.data.data;
    } else {
      throw new Error("Impossible récupérer les campagnes");
    }
  }

  async getCampus(recordTypeId) {
    const query = await this.axiosSfConnect({
      method: "get",
      url: "/lead/getCampus",
      params: { recordTypeId },
    });

    if (query.data.success) {
      return query.data.campus;
    } else {
      throw new Error("Impossible récupérer les campus");
    }
  }

  async getCursus(ecole, recordTypeId) {
    const query = await this.axiosSfConnect({
      method: "get",
      url: "/lead/getCursus",
      params: { ecole, recordTypeId },
    });

    if (query.data.success) {
      return query.data.data;
    } else {
      throw new Error("Impossible récupérer les cursus");
    }
  }

  async getCycles(school) {
    const query = await this.axiosSfConnect({
      method: "get",
      url: "/api/getCycles",
    });

    if (query.data.success) {
      return query.data.data;
    } else {
      throw new Error("Impossible récupérer les cycles");
    }
  }

  async getDepartments(ecole, recordTypeId) {
    const query = await this.axiosSfConnect({
      method: "get",
      url: "/lead/getDepartments"
    });

    if (query.data.success) {
      return query.data.data;
    } else {
      throw new Error("Impossible récupérer les départements");
    }
  }

  async getRecordTypeId(name) {
    let url = "/lead/getRecordTypeId"
    let params = { name }
    if (name === "EngineeringSchoolApplicant") {
      url = "/api/getRecordTypeId"
      params = { recordTypeName: name, objectName: "Account" }
    }
    if (name === "Engineering Schools Application") {
      url = "/api/getRecordTypeId"
      params = { recordTypeName: name, objectName: "Opportunity" }
    }
    const query = await this.axiosSfConnect({
      method: "get",
      url: url,
      params: params,
    });

    if (query.data.success) {
      return query.data.Id;
    } else {
      throw new Error("Impossible récupérer record type id");
    }
  }

  async getSessions(school) {
    const query = await this.axiosSfConnect({
      method: "get",
      url: "/api/getSessions",
      params: { school },
    });

    if (query.data.success) {
      return query.data.data;
    } else {
      throw new Error("Impossible récupérer les sessions");
    }
  }

  // IonisConnect
  async checkUser(email, school, division) {
    const query = await this.axiosSfConnect({
      method: "get",
      url: "/ionis-connect/checkUser",
      params: { email, school, division },
    });

    if (query.data.success) {
      return query.data.exist;
    } else {
      throw new Error("Impossible de vérifier l'utilisateur");
    }
  }

  async createUser(division, link, recordTypeId, school, status, user) {
    const query = await this.axiosSfConnect({
      method: "post",
      url: "/ionis-connect/createUser",
      data: { division, link, recordTypeId, school, status, user },
    });

    if (query.data.success) {
      return query.data.ionisConnect;
    } else {
      throw new Error("Impossible de vérifier l'utilisateur");
    }
  }

  async verifyUser(email, password, school, division) {
    const query = await this.axiosSfConnect({
      method: "get",
      url: "/ionis-connect/verifyUser",
      params: { email, password, school, division },
    });

    if (query.data.passwordMatches) {
      return query.data.ionisConnectId;
    } else {
      throw new Error("Impossible de vérifier l'utilisateur");
    }
  }

  async resetPassword(email, division) {
    const query = await this.axiosSfConnect({
      method: "post",
      url: "/ionis-connect/resetPassword",
      data: { email, division },
    });

    if (query.data.success) {
      return query.data;
    } else {
      throw new Error("Impossible de réinitialiser le mot de passe");
    }
  }

  async updatePassword(password, token) {
    const query = await this.axiosSfConnect({
      method: "post",
      url: "/ionis-connect/updatePassword",
      data: { password, token },
    });

    if (query.data.success) {
      return query.data;
    } else {
      throw new Error("Impossible de mettre à jour le mot de passe");
    }
  }

  async updateLastConnection(accountId) {
    const query = await this.axiosSfConnect({
      method: "post",
      url: "/ionis-connect/updateLastConnection",
      data: { accountId },
    });

    if (query.data.success) {
      return query.data;
    } else {
      throw new Error("Impossible de mettre à jour la dernière connexion");
    }
  }

  async verifyConfirmationToken(division, token) {
    const query = await this.axiosSfConnect({
      method: "get",
      url: "/ionis-connect/verifyConfirmationToken",
      params: { division, token },
    });

    if (query.data.success) {
      return query.data.data;
    } else {
      throw new Error("Impossible de vérifier le token");
    }
  }

  async getAccount(email, ionisConnectId, school) {
    const query = await this.axiosSfConnect({
      method: "get",
      url: "/ionis-connect/getAccount",
      params: { email, ionisConnectId, school },
    });

    if (query.data.success) {
      return query.data;
    } else {
      throw new Error("Impossible de récupérer les données utilisateurs");
    }
  }

  async getAccountExisting(email, division) {
    const query = await this.axiosSfConnect({
      method: "get",
      url: "/advance/getOpportunities",
      params: { email, division },
    });

    if (query.data.success) {
      return query.data.data;
    } else {
      throw new Error("Impossible de récupérer les données utilisateurs");
    }
  }

  async getOpportunities(accountId) {
    const query = await this.axiosSfConnect({
      method: "get",
      url: "/ionis-connect/getOpportunities",
      params: { accountId },
    });

    if (query.data) {
      return query.data.data;
    } else {
      throw new Error("Impossible de récupérer les données utilisateurs oppy");
    }
  }

  async updateAccount(account) {
    const query = await this.axiosSfConnect({
      method: "post",
      url: "/advance/updateAccount",
      data: { account }
    });

    if (query.data.success) {
      return query.data;
    } else {
      throw new Error("Impossible de mettre à jour le compte");
    }
  }

  async updateOpportunity(opportunity) {
    const query = await this.axiosSfConnect({
      method: "post",
      url: "/advance/updateOpportunity",
      data: { opportunity }
    });

    if (query.data.success) {
      return query.data.opportunity;
    } else {
      throw new Error("Impossible de mettre à jour l'opportunité");
    }
  }

  async getAttachments(accountId) {
    const query = await this.axiosSfConnect({
      method: "get",
      url: "/advance/getAttachments",
      params: { accountId }
    });

    if (query.data.success) {
      return query.data.data;
    } else {
      throw new Error("Impossible de récupérer les fichiers");
    }
  }

  async addAttachmentToSobject(files, accountId) {
    let formData = new FormData()
    formData.append("accountId", accountId)
    for (let i = 0; i < files.length; i++) {
      console.log('formData')
      console.log(files[i])
      console.log(files[i].fileValue)
      console.log(files[i].name)
      formData.append(`file${i}`, files[i])
      // formData.append(files[i].fileValue, files[i].name);
      // formData.append(files[i].fileValue, files[i])
    }
    const query = await this.axiosSfConnect({
      method: "post",
      url: "/api/addAttachment",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      }
    });

    if (query.data.success) {
      return query.data.data;
    } else {
      throw new Error("Impossible d'ajouter les fichiers");
    }
  }

  async checkAttachment(batchId, jobId) {
    const query = await this.axiosSfConnect({
      method: "get",
      url: "/api/checkAttachment",
      params: { batchId, jobId }
    });

    if (query.data.success) {
      return query.data;
    } else {
      throw new Error("Impossible de vérifier les fichiers");
    }
  }

  async resultAttachment(batchId, jobId) {
    const query = await this.axiosSfConnect({
      method: "get",
      url: "/api/resultAttachment",
      params: { batchId, jobId }
    });

    if (query.data.success) {
      return query.data;
    } else {
      throw new Error("Impossible de vérifier les fichiers");
    }
  }

  async getPayboxData(account, school, urls, opportunityId) {
    const query = await this.axiosSfConnect({
      method: "post",
      url: "/paybox/getPayboxDataInge",
      data: { account, opportunityId, school, urls }
    })

    if (query.data) {
      return query.data;
    } else {
      throw new Error("Impossible de récupérer les données Paybox");
    }
  }

  formatCreneaux(slots) {
    let creneaux = [];
    for (const slot of slots) {
      let label = "De " + slot.SlotStartTime__c + " à " + slot.SlotEndTime__c;
      if (slot.RemainingPlaces__c <= 0) {
        label += " - Complet";
      } else {
        label += " - reste " + slot.RemainingPlaces__c + " place";
      }
      if (slot.RemainingPlaces__c > 1) {
        label += "s";
      }

      creneaux.push({
        label: label,
        places: slot.RemainingPlaces__c,
        value: slot.Id,
      });
    }

    return creneaux;
  }
}

export default Salesforce;
