import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Grid } from "@mui/material";
import DropDownCheck from "components/fields/DropdownCheck";

const MultiCampus = ({ options }) => {
    const { control, watch } = useFormContext();
    const watchCampus = watch('lead.Campus__c') || [];

    return (
        <Grid item xs={12}>
            <DropDownCheck
                control={control}
                label="Campus souhaité(s)"
                multiple
                name="lead.Campus__c"
                options={options || []}
                required
            />
        </Grid>
    );
};

export default MultiCampus;
